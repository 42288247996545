import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Divider, Modal, Row, Space, notification } from "antd";
import { DownOutlined, UpOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import AppLoading from "../../../shared/component/AppLoading/AppLoading";
import LeadDetailNewTabs from "./LeadDetailNewTab";
import { API_SERVICE } from "../../../shared/services/api-services";
import { setScreenTitle } from "../../../shared/redux/common-reducer";
// import "./leadDetail.scss"



export type leadViewTypeScreen =
    | "mainLead"
    | "decisioningOutput"
    | "LeadDetailNewTabs"
    | "closeCase";

interface ILeadDetail {
    caseId?: any;
    setOpenLeadDetail:any
    setViewInvDisbursal:any
}

const LeadDetailNew: React.FC<ILeadDetail> = (props: ILeadDetail) => {
    const {
        caseId,
        setOpenLeadDetail,
        setViewInvDisbursal
        // isConnectorEditForm
    } = props;
    const [leadViewType, setLeadViewType] =
        useState<leadViewTypeScreen>("mainLead");
    const [isShowFullDetails, setIshowFullDetails] = useState(true);
    const [isShowBorrowerFullDetails, setIshowBorrowerFullDetails] = useState(false);
    const [isShowConnectorFullDetails, setIshowConnectorFullDetails] = useState(false);
    const [isDecisioning, setIsDecisioning] = useState(false);
    const [goToDocument, setGotoDocument] = useState("");
    const [isloading, setIsLoading] = useState(false);
    const [showView, setShowView] = useState(false);
    const [leadData, setLeadData] = useState([] as any);
    const dispatch = useDispatch();

    useEffect(() => {
        getLeadById()
    }, []);

    const getLeadById = () => {
        if (caseId) {
            setIsLoading(true)
            const params={
                platformSource:"OI_ADMIN"
            }
            API_SERVICE.getLeadById(caseId,params)
                .then(({ data }) => {
                    setLeadData(data?.payload);
                }).catch((e: any) =>
                    API_SERVICE.handleErrors(e)
                ).finally(() => { setIsLoading(false); setShowView(true) })
        }
    }

    //   useEffect(() => {
    //     let enumValues = localStorage.getItem("enumValues") as any;
    //     if (enumValues) {
    //       enumValues = JSON.parse(enumValues);
    //       const lenderStatusList = enumValues.find(
    //         (item) => item.propertyName === "LENDER_STATUS"
    //       );
    //       const caseStatusList = enumValues.find(
    //         (item) => item.propertyName === "CASE_STATUS"
    //       );
    //       setLenderStatus(lenderStatusList);
    //       setCaseStatus(caseStatusList);
    //     }
    //     setSelectedLenderList(
    //       leadData?.caseDetails?.lenderActivityHolder?.lenderActivityDetails
    //     );
    //     setHistoryDetails(leadData?.caseDetails?.caseHistoryHolder);
    //     setIsLoading(false);
    //     // getBureauScore();
    //   }, [leadData]);




    useEffect(() => {
        if (isDecisioning) {
            //   listDecisioning();
        }
        setIsDecisioning(false);
    }, [isDecisioning]);

    //   const listDecisioning = () => {
    //     setIsLoading(true);
    //     API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, false)
    //       .then(({ data }) => {
    //         setListDescisioningDetail(data?.payload);
    //       })
    //       .catch((e: any) => {
    //         notification.error({ message: API_SERVICE?.handleErrors(e) });
    //       })
    //       .finally(()=>setIsLoading(false));
    //   };

    //   const getJSONSchema = (jsonSchema: any) => {
    //     let str = jsonSchema;
    //     str = str?.replaceAll(
    //       /https:\/\/[^\/]+-distribution\.oneflotest\.in\/api\/dsa\//g,
    //       parameters.ApiUrlOne
    //     );
    //     str = str.replaceAll(
    //       /https:\/\/[^\/]+-common\.oneflotest\.in\/api\//g,
    //       parameters.ApiUrlTwo
    //     );
    //     str = str?.replaceAll(
    //       /https:\/\/[^\/]+-lender\.oneflotest\.in\/api\//g,
    //       parameters.ApiUrlThree
    //     );
    //     return JSON.parse(str);
    //   };

    const handleDocumentScreen = () => {
        setGotoDocument("documentStage");
        setLeadViewType("LeadDetailNewTabs");
    };

    const handleDecisionOutPutScreen = () => {
        setLeadViewType("decisioningOutput");
    };

    const renderLeadViewTypeScreen = () => {
        console.log('lead', leadData)
        return (
            <LeadDetailNewTabs
                leadData={leadData}
                getJSONSchema={{}}
                setLoading={setIsLoading}
                goToDocument={undefined}
                setGotoDocument={undefined} />
        );

    };

    const renderRequireDocuments = () => (
        <div className="content-box">

        </div>
    );

    const renderCustomerDetials = () => {
        return (
            <div style={{ width: "25%", minWidth: "280px" }} className="col-border-right-3">
                
                <div className="col-border-bottom-3" style={{ padding: "8px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p style={{ marginTop: "5px" }}>Customer Details</p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <div>
                            <div className="name-logo">
                                <span className="logo-header">
                                    {leadData?.contactDetails?.firstName
                                        ?.split("")
                                        ?.at(0)
                                        ?.toUpperCase()}
                                </span>
                            </div>
                        </div>
                        <div className="customer-details">
                            <p style={{ fontSize: "17px", marginBottom: "5px" }}>
                                {leadData?.contactDetails?.firstName}
                            </p>
                            {isShowFullDetails && (
                                <>
                                    <span className="item">
                                        Mobile No : {leadData?.contactDetails?.mobileNumber}
                                    </span>
                                    <span className="item">
                                        Applicant Type : {leadData?.applicationType}
                                    </span>
                                    {leadData?.applicationTypeKey === "BUSINESS" ? (
                                        <span className="item">
                                            Business Type : {leadData?.applicationSubType}
                                        </span>
                                    ) : (
                                        <span className="item">
                                            Individual Type : {leadData?.applicationSubType}
                                        </span>
                                    )}
                                    <span className="item">Loan Type: {leadData?.loanType}</span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-border-bottom-3" style={{ padding: "8px" }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "5px",
                        }}
                    >
                        <span style={{ fontSize: "16px" }}>Connector Details</span>
                        <div style={{ float: "right" }}>
                            <Button
                                type="text"
                                size="small"
                                className="borrower-icon"
                                onClick={() =>
                                    setIshowConnectorFullDetails(!isShowConnectorFullDetails)
                                }
                            >
                                {isShowConnectorFullDetails ? <DownOutlined /> : <UpOutlined />}
                            </Button>
                        </div>
                    </div>
                    <div className="customer-details">
                        {isShowConnectorFullDetails && (
                            <>
                                <span className="item">
                                    Connector Name:{" "}
                                    {leadData?.sourceOfCase == "CONNECTOR" ? leadData?.caseDetails?.connectorDetails?.userDetails?.fullName : ""}
                                </span>
                                <span className="item">
                                    Tentative Payout %:{" "}
                                    {leadData?.caseDetails?.loanDetails?.expectedInterestRate ?? 0}
                                </span>
                                <span className="item">
                                    Assign To:{" "}
                                    {leadData?.caseDetails?.assignedToUserDetails?.fullName}
                                </span>
                                <span className="item">
                                    Assign To Back Office:{" "}
                                    {leadData?.caseDetails?.backOfficeUserDetails?.fullName}
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        )
    };

    const renderCaseDetials = () => (
        <div style={{ marginTop: "3px" }}>
            <div className="manage-form">
                <div style={{ display: "flex", background: "#fff" }}>
                    {renderCustomerDetials()}
                    {renderLeadViewTypeScreen()}
                </div>
            </div>
        </div>
    );

    if (showView) {
        return (
            <>
            <div style={{ marginBottom: "3px" }}>
                <Button
                    type="link"
                    style={{
                    float:"right"
                    }}
                    onClick={() => {
                    setOpenLeadDetail(false); 
                    setViewInvDisbursal(true)     
                    dispatch(setScreenTitle("Invoice"))                     
                    }}>
                    Back to Invoice Disbursal
                </Button>
            </div>
                {renderCaseDetials()}
                {isloading && <AppLoading />}
            </>
        )
    }
    return (
        <>
            {isloading && <AppLoading />}
        </>
    );
};
export default LeadDetailNew;
