// @ts-ignore
import { Form as Formio } from "react-formio";
import "./leadDetail.scss";

interface IProductSteps {
    // getJSONSchema:any;
    getTaskData: any;
    formJsonSchema: any;
    currentSteps: any;
}

const ProductSteps: React.FC<IProductSteps> = (props: IProductSteps) => {
    const {
        // getJSONSchema,
        getTaskData,
        formJsonSchema,
        currentSteps,
    } = props;
    //const jsonSchema = getJSONSchema(getTaskData?.jsonSchema ?? "{}");
    // const jsonSchema = getJSONSchema(formJsonSchema ?? "{}");
    let tmpJsonData = JSON?.parse(formJsonSchema ?? "{}") as any;
    let tmpFormData = JSON?.parse(getTaskData?.formData ?? "{}") as any;
    console.log('fu', formJsonSchema)
    return (
        <>
            <div className="dynamic-formio">
                <Formio
                    options={{
                        readOnly: true,
                        renderMode: "html",
                    }}
                    key={currentSteps}
                    form={tmpJsonData}
                    jsonSchema={tmpJsonData}
                    submission={{ data: tmpFormData }}
                />
            </div>
        </>
    );
};

export default ProductSteps;
