import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, DatePicker, Form, Row, Tabs, message, notification } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import Disbursals from "./Disbursals/Disbursals";
import Invoices from "./Invoices/Invoices";
import { ReactComponent as ExcelIcon } from "../../assets/images/excel.svg";
import FormSelect from "../../shared/component/FormSelect/FormSelect";
import "./accounting.scss"
import AppLoading from "../../shared/component/AppLoading/AppLoading";
import { API_SERVICE } from "../../shared/services/api-services";
import CreateInvoice from "./Disbursals/CreateInvoice";
import LeadDetailNew from "./LeadDetail/LeadDetail";
import InvoiceDetails from "./Invoices/InvoiceDetails";
import IncompleteDisbursements from "./Incomplete Disbursement/IncompleteDisbursement";
import { saveByteArray } from "../../shared/Utility/Utility";
import { RootState } from "../../shared/redux/store";
import { setInvoiceId, setInvoicePageNo, setInvoicePageSize, setInvoiceTenantId } from "../../shared/redux/invoice-reducer";
import { setIncompleteDisbursementPageNo, 
    setIncompleteDisbursementPageSize } 
    from "../../shared/redux/incompleteDisbursementReducer";
    import { setDisbursalPageNo, 
        setDisbursalPageSize} from "../../shared/redux/disbursal-reducer";

    const { RangePicker } = DatePicker;


const Accounting: React.FC<any> = () => {
    const [tabKey, setTabKey] = useState("DISBURSALS");
    const [openCreateInvoice, setOpenCreateInvoice] = useState(false);
    const [disbursalStatus , setDisbursalStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [tenantsList, setTenantsList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [lenderList, setLenderList] = useState([]);
    const [viewInvDisbursal, setViewInvDisbursal] = useState(false)
    const [lenderList1, setLenderList1] = useState([]);
    const [productsList1, setProductsList1] = useState([]as any);    
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [tenantBusinessInfoId, setTenantBusinessInfoId] = useState("")
    const [loanProductIds, setLoanProductIds] = useState("")
    const [lenderIds, setLenderIds] = useState("")
    const [incompleteDisbList, setIncompleteDisbList] = useState([]);
    const [completeDisbList, setcompleteDisbList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountForm] = Form.useForm() as any;
    const [invoiceDetailsValue, setInvoiceDetailsValue] = useState([] as any)
    const [selectedDisLendIds, setSelectedDisLendIds] = useState([] as any)
    const [disbLenderList, setDisbLenderList] = useState([] as any)
    const [disbProductList, setDisbProductList] = useState([] as any)
    const [openLeadDetail, setOpenLeadDetail] = useState(false);
    const [invoiceData, setInvoiceData] = useState([] as any);
    const [disbTotalCount, setDisbTotalCount] = useState(0);
    const [invTotalCount, setInvTotalCount] = useState(0);
    const [inCompDisbtotalCount, setInCompDisbTotalCount] = useState(0);
    const [disbTenantId, setDisbTenantId] = useState([]as any)
    const [selectedCase, setSelectedCase] = useState(0);
    const [totalDisbursalAmount, setTotalDisbursalAmount] = useState(0);
    const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const invoice: any = useSelector((state: RootState) => state.invoice);
    const disbursal: any = useSelector((state: RootState) => state.disbursal);
    const incompleteDisbursal: any = useSelector((state: RootState) => state.incompleteDisbursement);

    const dispatch = useDispatch();

    useEffect(() => {
        getTenantList();
        getProductList();
        listAllTheFinanciers();     
    }, [])

    useEffect(() => {
        if (tabKey == "DISBURSALS") {
            setDisbursalStatus("COMPLETE")
            // getIncompleteDisbursalList(disbursal.pageNo, disbursal.pageSize, tenantBusinessInfoId, startDate, endDate, loanProductIds, lenderIds, "COMPLETE");
        } else if (tabKey == "INCOMPLETE_DISBURSEMENT") {
            setDisbursalStatus("INCOMPLETE")
            getIncompleteDisbursalList(incompleteDisbursal.pageNo, incompleteDisbursal.pageSize, tenantBusinessInfoId, startDate, endDate, loanProductIds, lenderIds, "INCOMPLETE");
        }
        else{
             getInvoiceList(invoice.pageNo, invoice.pageSize, tenantBusinessInfoId, loanProductIds, startDate, endDate)
            }      
    }, [tabKey])

    const getTenantList = () => {
        setIsLoading(true);
        API_SERVICE.getListTenants({ pageSize: 100 })
            .then(({ data }) => {
                const displayTenantList = data?.payload?.content?.map((item: any) => {
                return {
                        key: item?.businessInfoId,
                        value: item?.businessName
                    }
                });
                setTenantsList(displayTenantList);
            })
            .catch((e: any) =>
                API_SERVICE.handleErrors(e)
            )
            .finally(() =>
                setIsLoading(false)
            );
    };

    const getProductList = () => {
       setIsLoading(true);
        API_SERVICE.getLoanProductList()
            .then(({ data }) => {
                const displayProductList = data?.payload?.map((item: any) => {
                    return {
                        key: item?.loanProductId,
                        value: item?.description
                    }
                });
                const displayProductList1 = data?.payload?.map((item: any) => {
                    return {
                        id: item?.loanProductId,
                        loanProductName: item?.loanProductName
                    }
                });
                setProductsList(displayProductList);
                setProductsList1(displayProductList1);
            })
            .catch((e: any) =>
                API_SERVICE.handleErrors(e)
            )
            .finally(() =>
                setIsLoading(false)
            );
            
    };
    const listAllTheFinanciers = () => {
        API_SERVICE.listAllTheFinanciers().then(({ data }: any) => {
            const displayNameList = data?.payload?.map((listVal: any) => {
                return {
                    key: listVal?.businessInfoId,
                    value: listVal?.businessName,
                }
            });
            const displayNameList1 = data?.payload?.map((item: any) => {
                return {
                    id: item?.businessInfoId,
                    lenderName: item?.businessName
                }
            });
            setLenderList(displayNameList);
            setLenderList1(displayNameList1);
        }).catch((e: any) =>
            API_SERVICE.handleErrors(e)
        )
            .finally(() =>
                setIsLoading(false)
            );

    };

    const getIncompleteDisbursalList = (
        pageNo: number = 1,
        pageSize: number = 10,
        tenantBusinessInfoId:any=null,
        startDate:any = null,
        endDate:any = null,
        loanProductIds:any=null,
        lenderIds:any=null,
        invoiceDisbursalStatus : any = null,
    ) => {
        setLoading(true);
        const params = {
            pageNo: pageNo,
            pageSize: pageSize,
            tenantBusinessInfoIds:tenantBusinessInfoId,
            loanProductIds:loanProductIds,
            lenderIds:lenderIds,
            invoiceDisbursalStatus : invoiceDisbursalStatus,
            startDate:startDate,
            endDate:endDate
        };
            API_SERVICE.getIncompleteDisbursalList(params)
              .then(({ data }: any) => {
                if ((data?.payload?.content?.length ?? 0) > 0) {
                    data?.payload?.content.map((inCompDisb: any) => {
                    inCompDisb.key = inCompDisb.id;
                });
                
              } 
              if(invoiceDisbursalStatus == "INCOMPLETE"){
                  setIncompleteDisbList(data?.payload?.content ?? []);
                  setInCompDisbTotalCount(data?.payload?.totalElements ?? 0);                  
              }else {
                setcompleteDisbList(data?.payload?.content ?? [])
                setDisbTotalCount(data?.payload?.totalElements ?? 0);
                setSelectedDisLendIds("")
              }
              })
              .catch((e: any) => {
                API_SERVICE.handleErrors(e);
              })
              .finally(() => {
                setLoading(false);
              });
        };
    
    const handleActiveTabChange = (key:any) => {
        accountForm?.setFieldsValue({
            tenantBusinessInfoId: (key === "DISBURSALS")?disbTenantId:[],//[],//null,
            productType: [],
            lenderName: [],
            dateRange: null
        });
        setTenantBusinessInfoId((key === "DISBURSALS")?disbTenantId:"");
        setLoanProductIds("");
        setLenderIds("");
        setStartDate("");
        setEndDate("");
        setTabKey(key);
        setDisbTotalCount(0)
        setInCompDisbTotalCount(0)
        setInvTotalCount(0)
     };
    
    const getInvoiceList = (
        pageNo: number = 1,
        pageSize: any = 10,
        tenantIds: string = "",
        loanProductIds: any = [],
        startDate: string = "",
        endDate: string = "",
    ) => {
        const param = {
            tenantIds: tenantIds,
            loanProductIds: loanProductIds,
            startDate: startDate,
            endDate: endDate,
            pageNo: pageNo,
            perPage: pageSize
        }
        setIsLoading(true)
        API_SERVICE.getInvoiceList(param)
            .then(({ data }) => {
                setInvoiceData(data?.payload?.content);
                setInvTotalCount(data?.payload?.totalElements)
            }).catch((e: any) =>
                API_SERVICE.handleErrors(e)
            ).finally(() => setIsLoading(false))
    }

    const handleDateChange = (values:any) =>{
        let sDate, eDate
        if (values) {
            sDate = values[0].format("YYYY-MM-DD")
            eDate = values[1].format("YYYY-MM-DD")
            setStartDate(values[0].format("YYYY-MM-DD"))
            setEndDate(values[1].format("YYYY-MM-DD"))
           }
           if (tabKey == "DISBURSALS" || tabKey == "INCOMPLETE_DISBURSEMENT"){
            getIncompleteDisbursalList(1,10,tenantBusinessInfoId, sDate, eDate, loanProductIds, lenderIds,disbursalStatus)
            }
            else{
                getInvoiceList(1,10, tenantBusinessInfoId, loanProductIds, sDate, eDate)
            }      
        dispatch(setDisbursalPageNo(1))
        dispatch(setInvoicePageNo(1))
        dispatch(setIncompleteDisbursementPageNo(1))
        // if (values===""){
            setSelectedDisLendIds(0)            
            setSelectedCase(0)
            setTotalDisbursalAmount(0)
            setTotalInvoiceAmount(0)
            setSelectedRowKeys([])
        // }
    }
    const handleTenantChange = (values:any) =>{
        if (values === undefined || values?.length === 0){
            setTenantBusinessInfoId("")
            if (tabKey === "INCOMPLETE_DISBURSEMENT"){
                getIncompleteDisbursalList(1,10,"", startDate, endDate, loanProductIds, lenderIds,disbursalStatus)
            }
            else if (tabKey==="INVOICES"){
                 getInvoiceList(1,10, values.toString(), loanProductIds, startDate, endDate)
            }
        }
         if (tabKey == "DISBURSALS") {
            setDisbTenantId(values)
            setSelectedDisLendIds(0)
            setInvoiceDetailsValue([])
          } 
           if (values?.length>0){
            setTenantBusinessInfoId(values.toString())
        }    
        dispatch(setDisbursalPageNo(1))
        dispatch(setInvoicePageNo(1))
        dispatch(setIncompleteDisbursementPageNo(1))
        setSelectedCase(0)
        setTotalDisbursalAmount(0)
        setTotalInvoiceAmount(0)
        setSelectedRowKeys([])
    }

    const handleLoanProductIdChange =(values:any) =>{
       setLoanProductIds(values.toString())
        if (tabKey == "DISBURSALS" || tabKey == "INCOMPLETE_DISBURSEMENT"){
            getIncompleteDisbursalList(1,10,tenantBusinessInfoId,startDate, endDate, values.toString(), lenderIds,disbursalStatus)
        }
        else{
            getInvoiceList(1,10, tenantBusinessInfoId, values.toString(), startDate, endDate)
        }
         const search = productsList1.filter((item: any)=>
            values.includes(item.id)
       );
        setDisbProductList(search)
        dispatch(setDisbursalPageNo(1))
        dispatch(setInvoicePageNo(1))
        dispatch(setIncompleteDisbursementPageNo(1))
        setSelectedDisLendIds(0)
        setSelectedCase(0)
        setTotalDisbursalAmount(0)
        setTotalInvoiceAmount(0)
        setSelectedRowKeys([])
    }

    const handleLenderChange = (values:any) =>{
        setLenderIds(values.toString())
        
        if (tabKey == "DISBURSALS" || tabKey == "INCOMPLETE_DISBURSEMENT"){
            getIncompleteDisbursalList(1,10,tenantBusinessInfoId,startDate, endDate, loanProductIds,values.toString(), disbursalStatus)
        }
        const search = lenderList1.filter((item: any)=>
             values.includes(item.id)
        );
        setDisbLenderList(search)
        dispatch(setDisbursalPageNo(1))
        dispatch(setIncompleteDisbursementPageNo(1))
        setSelectedCase(0)
        setTotalDisbursalAmount(0)
        setTotalInvoiceAmount(0)
        setSelectedRowKeys([])
    }

    const handleDownload = () =>{
        const invoiceDisbursalList = completeDisbList.filter((item:any) => selectedDisLendIds.toString().includes(item.disbursalId));
        if (invoiceDisbursalList.length == 0){
            notification.error({message:"Select atleast one disbursal to download"})
        }
        else{
            const payload = {
                invoiceDisbursalList:invoiceDisbursalList,
            }
            let filename = "file.xlsx"
            API_SERVICE.getDownloadExcel(payload)
            .then(async({ data }: any) => {
                saveByteArray([data], filename);
                setLoading(false);
            })
            .catch(async (e: any) => {
                const data = JSON.parse(await e.response.data.text());
                const message = data?.payload?.invoiceDisbursalList;
                notification.error({ message: API_SERVICE.handleErrors(message) });
                setLoading(false);
            });
        }
    }
    const renderAccounting = () => (
        <> 
            {viewInvDisbursal ? 
                <InvoiceDetails setIsLoading={setIsLoading}
                setViewInvDisbursal={setViewInvDisbursal}
                setOpenLeadDetail={setOpenLeadDetail} 
             />
                :
            (
            <>
            <Form form={accountForm}>
            <Row gutter={[16, 16]} style={{ padding: "10px", width: "100%" }}>                    
                    <Col span={8}>
                        {tabKey === "INVOICES" ?
                        <FormSelect
                            name="tenantBusinessInfoId"
                            isBorder
                            placeholder="Tenant"
                            mode= "multiple"
                            options={tenantsList}
                            optionFilterProp="children"
                            onChange={(values)=>handleTenantChange(values)}
                             maxTagCount="responsive"                      
                        />:
                        <FormSelect
                            name="tenantBusinessInfoId"
                            isBorder
                            placeholder="Tenant"
                            options={tenantsList}
                            optionFilterProp="children"
                            onChange={(values)=>handleTenantChange(values)}
                                
                        />}
                    </Col>
                    <Col span={8}>
                        <FormSelect
                            name="productType"
                            isBorder
                            mode="multiple"
                            placeholder="Product"
                            options={productsList}
                            maxTagCount="responsive"
                            optionFilterProp="children"
                            onChange={(values)=>handleLoanProductIdChange(values)}                            
                        />
                    </Col>
                        {
                            (tabKey == "DISBURSALS" || tabKey == "INCOMPLETE_DISBURSEMENT") &&
                            <Col span={8}>
                                <FormSelect
                                    name="lenderName"
                                    isBorder
                                    mode="multiple"
                                    placeholder="Lender"
                                    options={lenderList}
                                    maxTagCount="responsive"
                                    optionFilterProp="children"
                                    onChange={(values) => handleLenderChange(values)}
                                />
                            </Col>
                        }
                        <Col span={8}>
                            <Form.Item
                                name="dateRange"
                                className="field-bg field-border"
                            >
                                <RangePicker
                                    placeholder={["From Disbursal Date", "To Disbursal Date"]}
                                    style={{ width: "250px" }}
                                    onChange={(values) => handleDateChange(values)}
                                />
                            </Form.Item>
                        </Col>
            </Row>
            </Form>
            <div style={{ padding: "10px" }}>
                <Tabs
                    activeKey={tabKey}
                    onChange={(key: any) => handleActiveTabChange(key)}
                    tabBarExtraContent={tabKey == "DISBURSALS" && (
                        <Form.Item>
                            <Button
                                size="large"
                                className="submit-excel"
                                type="text"
                                icon={<ExcelIcon />}
                                onClick={handleDownload}
                            >
                                Download Selected
                            </Button>
                        </Form.Item>
                    )}
                >
                    <TabPane tab="Disbursals" key="DISBURSALS">
                        <Disbursals
                            setOpenCreateInvoice={setOpenCreateInvoice}
                            setInvoiceDetailsValue={setInvoiceDetailsValue}
                            invoiceDetailsValue={invoiceDetailsValue}            
                            tenantBusinessInfoId={tenantBusinessInfoId}
                            totalCount={disbTotalCount}
                            loanProductIds={loanProductIds}
                            lenderIds={lenderIds}
                            startDate={startDate}
                            endDate={endDate}
                            completeDisbList={completeDisbList}
                            getIncompleteDisbursalList={getIncompleteDisbursalList}
                            setSelectedDisLendIds={setSelectedDisLendIds}
                            selectedDisbRows={selectedDisLendIds}   
                            disbursalStatus={disbursalStatus}
                            tabKey={tabKey}
                            setSelectedCase={setSelectedCase}
                            selectedCase={selectedCase}
                            setTotalDisbursalAmount={setTotalDisbursalAmount}
                            totalDisbursalAmount={totalDisbursalAmount}
                            setTotalInvoiceAmount={setTotalInvoiceAmount}
                            totalInvoiceAmount={totalInvoiceAmount}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                        />
                    </TabPane>
                    <TabPane tab="Invoices" key="INVOICES" >
                       <Invoices
                             setViewInvDisbursal = {setViewInvDisbursal}
                             tenantBusinessInfoId={tenantBusinessInfoId}
                             loanProductIds={loanProductIds}
                             startDate={startDate}
                             endDate={endDate}
                             setIsLoading={setIsLoading}
                             tabKey={tabKey}
                             getInvoiceList={getInvoiceList}
                             invoiceData={invoiceData}
                             totalCount={invTotalCount}                                                        
                        />
                    </TabPane>
                    <TabPane tab="Incomplete Disbursement" key="INCOMPLETE_DISBURSEMENT">
                        <IncompleteDisbursements
                            tenantBusinessInfoId = {tenantBusinessInfoId}
                            totalCount={inCompDisbtotalCount}
                            loanProductIds={loanProductIds}
                            lenderIds={lenderIds}
                            startDate = {startDate}
                            endDate = {endDate}
                            incompleteDisbList = {incompleteDisbList}
                            getIncompleteDisbursalList = {getIncompleteDisbursalList}
                            disbursalStatus={disbursalStatus}
                            tabKey={tabKey}
                        />
                    </TabPane>
                </Tabs>
            </div>
          </>)} 
        </>
    );

    const render = () => (
        <>
        {openLeadDetail ?
            <LeadDetailNew 
                setOpenLeadDetail={setOpenLeadDetail}
                setViewInvDisbursal={setViewInvDisbursal}
            />
            : (<></>)
        }
        </>    
    )
            
    const renderCreateInvoice = () => (
        <CreateInvoice
            setOpenCreateInvoice={setOpenCreateInvoice}
            tenantBusinessInfoId={tenantBusinessInfoId}
            setInvoiceDetailsValue = {setInvoiceDetailsValue}
            getIncompleteDisbursalList={getIncompleteDisbursalList}
            invoiceDetailsValue={invoiceDetailsValue}            
            startDate={startDate}
            endDate={endDate}
            loanProductIds={loanProductIds}
            lenderIds={lenderIds}
            setSelectedDisLendIds={selectedDisLendIds}
            setTabKey={setTabKey}            
        />
    );

    return (
        <>
            
            <div className={"layout-main"} style={{ marginTop: "20px" }}>
                {
                    openCreateInvoice ? renderCreateInvoice() : renderAccounting()
                }                
            </div>
            {isLoading &&
                <AppLoading />
            }
        </>
    )
};

export default Accounting;