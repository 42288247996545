export const PATH_EMBEDDING_IFRAME = "embedding-iframe";
export const PATH_DASHBOARD = "/dashboard";
export const PATH_PROPERTIES = "/properties";
export const PATH_APP_CONTENT = "/app-content";
export const PATH_DECISIONING = "/decisioning";
export const PATH_TENANT_ASSIGN = "/tenant-assign";
export const PATH_DC_CONFIGURATION = "/dc-configuration";
export const PATH_REPORTS = "/reports";
export const PATH_PRODUCT = "/product";
export const PATH_COUPONS = "/coupons";
export const PATH_DELETE_CASES = "/delete-cases";
export const PATH_PRICING = "/pricing";
export const PATH_MANAGE_CATEGORY = "/manage-category";
export const PATH_MANAGE_DOCUMENT_TYPE = "/manage-document-type";
export const PATH_DOCUMENT = "/document-screen";
export const PATH_PRODUCTS = "/products";
export const PATH_DISPOSITION = "/disposition";
export const PATH_DECISIONING_AUDIT = "/decisioningaudit";
export const PATH_PRODUCT_PLAN = "/productPlan";
export const PATH_ACCOUNTING = "/accounting";
