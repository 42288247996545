import Table, { ColumnsType } from "antd/es/table";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../shared/redux/store";
import { setIncompleteDisbursementPageNo, 
    setIncompleteDisbursementPageSize } 
    from "../../../shared/redux/incompleteDisbursementReducer";
import ViewDocument from "../../../shared/component/ViewDocument/ViewDocument";

interface IIncompleteDisbursements{
    tenantBusinessInfoId:any,
    loanProductIds:any
    lenderIds:any
    startDate:any,
    endDate:any,
    incompleteDisbList:any,
    getIncompleteDisbursalList:any, 
    totalCount:number,
    disbursalStatus:any,
    tabKey:any
}

const IncompleteDisbursements: React.FC<IIncompleteDisbursements> = (props:IIncompleteDisbursements) => {
    const {tenantBusinessInfoId, 
        loanProductIds, 
        lenderIds, 
        startDate, 
        endDate, 
        incompleteDisbList, 
        getIncompleteDisbursalList,  
        totalCount,
        disbursalStatus,
        tabKey
    }= props
    const [openViewDocument, setOpenViewDocument] = useState(false)
    const [businessDocumentId, setBusinessDocumentId] = useState("");
    const incompleteDisbursal: any = useSelector((state: RootState) => state.incompleteDisbursement);

    const dispatch = useDispatch();
   
    useEffect(() => {
        if (tabKey==="INCOMPLETE_DISBURSEMENT" && tenantBusinessInfoId.length > 0) {
            getIncompleteDisbursalList(incompleteDisbursal.pageNo, incompleteDisbursal.pageSize, tenantBusinessInfoId, startDate, endDate, loanProductIds, lenderIds, "INCOMPLETE");
        }
    }, [tenantBusinessInfoId])

    const columns : ColumnsType<any> =[
        {
            title: "Case ID",
            dataIndex: "caseId",
            key: "caseId",
        },
        {
            title: "Disbursal ID",
            dataIndex: "disbursalId",
            key: "disbursalId",
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            key: "customerName",
        },
        {
            title: "Tenant DSA Name",
            dataIndex: "tenantName",
            key: "tenanatName",
        },
        {
            title: "Lender",
            dataIndex: "lenderName",
            key: "lenderName",
        },
        {
            title: "Product Type",
            dataIndex: "productType",
            key: "productType",
        },
        {
            title: "Disbursal Amount",
            dataIndex: "disbursedAmount",
            key: "disbursedAmount",
        },
        {
            title: "Disbursal Date",
            dataIndex: "disbursalDate",
            key: "disbursalDate",
        },
        {
            title: "Disbursed By",
            dataIndex: "disbursedBy",
            key: "disbursedBy",
        },
        {
            title: "Lender Identifier",
            dataIndex: "lenderUniqueIdentifier",
            key: "lenderUniqueIdentifier",
        },
        {
            title: "Payout %",
            dataIndex: "payoutPercentage",
            key: "payoutPercentage",
        },
        {
            title: "Proof of Disbursal",
            dataIndex: ["proofOfDisbursal"],
            key: "action",
            align: "center",
            width: 150,
            render: (value, rec) => {
                return (
                    <>
                        {value?.documentIdList?.length > 0 ? (
                            <FormIconButton
                                type={"eye"}
                                onClick={() => {
                                     if (value?.documentIdList?.at(0)) {
                                        setBusinessDocumentId(value?.documentIdList?.at(0))
                                        setOpenViewDocument(true);
                                    }
                                }}
                                tooltipTitle={"Proof of Disbursal"}
                            />
                        ) : (
                                    <FormIconButton
                                        type={"eyeGrey"}
                                        tooltipTitle={"Proof of Disbursal"}
                                        isDisabled
                                    />
                            
                        )}                        
                    </>
                )
            }
        }
    ]

    const handlePaginationChange = (pagination:any) => {
        let page = pagination.pageSize !== incompleteDisbursal.pageSize ? 1 : pagination.current;
        dispatch(setIncompleteDisbursementPageNo(page));
        dispatch(setIncompleteDisbursementPageSize(pagination.pageSize));
        getIncompleteDisbursalList(page, pagination.pageSize, tenantBusinessInfoId, startDate, endDate, loanProductIds, lenderIds,disbursalStatus);
      };

    return(
        <>
            <Table
                columns={columns}
                dataSource={(incompleteDisbList)}
                className="dynamic-pagination-checkbox"
                size="small"
                tableLayout="fixed"
                scroll={{ x: "max-content" }}
                pagination={{
                    position: ["bottomCenter"],
                    current:  incompleteDisbursal.pageNo,
                    pageSize: incompleteDisbursal.pageSize,
                    total: totalCount
                }}
                onChange={handlePaginationChange}
            >
            </Table>
            <ViewDocument
                showPopDocument={openViewDocument}
                setIsShowPopDocument={setOpenViewDocument}
                businessDocumentId={businessDocumentId}
            />
        </>
    )
}

export default IncompleteDisbursements

