import React from "react";
import { Form, Button, DatePicker } from "antd";
import type { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import "./FormDatePicker.scss";
import { Rule } from "antd/es/form";


interface IFormDatePicker {
    label?: string;
    name?: string;
    isRequired?: boolean;
    placeholder?: string;
    disableDate?: any;
    dateFormat?: "YYYY-MM-DD";
    isBorder?: boolean;
    classNameForm?: string;
    isDisabled?: boolean
    rules?: Rule[] | undefined;
   
}

const FormDatePicker = (props: IFormDatePicker) => {
    const { label,
        name,
        isRequired,
        placeholder,
        // disabledDate,
        dateFormat,
        isBorder,
        isDisabled,
        classNameForm,
        rules
    } = props;

    const disabledDate: RangePickerProps['disabledDate'] = current => {
        // Can not select days before today 
        return moment().add(-100, 'years') >= current || current && current > moment().endOf("day");
    };

    return (
        <Form.Item
            className={`field-bg ${isBorder ? "field-border" : ""} ` + classNameForm}
            name={name}
            label={label}
            rules={[
                {
                    required: isRequired,
                    message: `Please Enter your ${placeholder ?? label ?? "Date"}`,
                },
            ]}
        >
            <DatePicker
                disabledDate={disabledDate}
                disabled={isDisabled}
                className="custom-input"
                placeholder={placeholder}
                size="large"
                format={dateFormat}
            />
        </Form.Item>
    );
};

export default FormDatePicker;
