import { Button, Divider, Modal, notification, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./viewDocument.scss";
import { API_SERVICE } from "../../services/api-services";
import AppLoading from "../AppLoading/AppLoading";

interface IViewDocument {
    showPopDocument: boolean;
    setIsShowPopDocument: (value: boolean) => void;
    businessDocumentId: string;
}

const ViewDocument: React.FC<IViewDocument> = (props: IViewDocument) => {

    const { setIsShowPopDocument, showPopDocument, businessDocumentId } = props;
    const [documentData, setDocumentData] = useState(null as any);
    const [docType, setDocType] = useState(null as any);
    const [isPDF, setIsPDF] = useState(false);
    const [isExcel, setIsExcel] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        showDocumentImage();
    }, [businessDocumentId])

    const showDocumentImage = () => {
        if (businessDocumentId?.length > 0) {
            setIsLoading(true)
            API_SERVICE.getUploadDocument(businessDocumentId).then(({ data }) => {
                if (data?.payload?.fileExtn === "pdf") {
                    setDocumentData(data?.payload?.documentDetails);
                    setDocType("application/pdf");
                    setIsPDF(true);
                } 
                else if ((data?.payload?.fileExtn === "xlsx") || (data?.payload?.fileExtn === "xls"))
                    {
                        setDocumentData(data?.payload?.documentDetails);
                        setDocType(data?.payload?.fileExtn === "xlsx"?"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":"application/vnd.ms-excel");
                        setIsExcel(true);                        
                    }
                else {
                setDocumentData(data?.payload?.documentDetails);
                    
                setDocType(data?.payload?.fileExtn);
            }
                setIsShowPopDocument(true);

            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            }).finally(() => setIsLoading(false))
        }
    };

    return (
        <Modal
            width={isPDF ? 800 : 600}
            open={showPopDocument}
            okText="Yes"
            cancelText="No"
            title=""
            centered
            closable={false}
            maskClosable={false}
            footer={false}
        >
            
            <Space direction="vertical" style={{ padding: "10px", width: "100%" }}>
                <div className="cardmain">
                    <div className="cardinner">
                        {
                            isLoading ? (
                                <div >
                                    <Spin tip="We are processing your request, please wait" size="large" > </Spin>
                                </div>
                            ):                        
                        documentData ? (
                            isPDF ? (
                                <iframe src={"data:" + docType + ";base64," + documentData} className="iframe-pdf-view"></iframe>
                            ) : 
                            isExcel ? 
                            (   
                                <div className="iframe-doc-view">
                                    <div 
                                    style={{
                                        marginTop:"20px"
                                    }}
                                    >
                                        No preview available. It has been downloaded to your computer for you to open.
                                    </div>
                                    <iframe src={"data:" + docType + ";base64," + documentData } ></iframe>
                                    
                                </div>
                                
                            ) : (
                                <img src={"data:" + docType + ";base64," + documentData} alt="icons" className="img-address" />
                            )
                        ) : null}
                        <Divider style={{ marginTop: "10px" }} />
                    </div>
                </div>
                
                <Button
                    type="primary"
                    size="large"
                    className="close-btn"
                    onClick={() => setIsShowPopDocument(false)}
                >Close</Button>
            </Space>            
        </Modal>
    )
};

export default ViewDocument;