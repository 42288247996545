import { useEffect, useState } from "react";
import { Button, Checkbox, Form, Space, Table, Tabs, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { API_SERVICE } from "../../../shared/services/api-services";
import passIcon from "../../../assets/images/pass.svg";
import failIcon from "../../../assets/images/fell.svg";
import info from "../../../assets/images/info.svg";


interface IDecisioning {
    setIsLoading: any;
    leadData: any;
}

const Decisioning: React.FC<IDecisioning> = (props: IDecisioning) => {
    const {
        setIsLoading,
        leadData,
    } = props;
    const [descisioningDetail, setListDescisioningDetail] = useState([] as any);
    const [selectedLenderExecutionDataList, setSelectedLenderExecutionDataList] =
        useState([] as any);
    const [decisioningnTabkey, setDecisioningTabkey] =
        useState(leadData?.journeyConfigResponseList?.at(0)?.journeyId);

    useEffect(() => {
        listDecisioning();
    }, []);

    const listDecisioning = () => {
        setIsLoading(true);
        //change true
        let tmpId = leadData?.journeyConfigResponseList?.map((item: any) => {
            return item?.journeyId
        })
        API_SERVICE.listDecisioning(leadData?.caseDetails?.caseId, true, '', tmpId)
            .then(({ data }) => {
                setDecisioningTabkey(data?.payload?.journeyWiseDecisioningDataList?.at(0)?.journeyId)
                setListDescisioningDetail(data?.payload?.journeyWiseDecisioningDataList);
            })
            .catch((e: any) => {
                notification.error({ message: API_SERVICE?.handleErrors(e) });
            })
            .finally(() => setIsLoading(false));
    };

    const columns: ColumnsType<any> = [
        {
            title: "Lender Name",
            dataIndex: "lenderName",
            key: "lenderName",
        },
        {
            title: "Purpose",
            dataIndex: "decisioningPurpose",
            key: "decisioningPurpose",
        },
        {
            title: "Gating Eligibility Passed",
            dataIndex: "gatingPassed",
            key: "gatingPassed",
            align: "center",
            render: (_: any, record: any) => {
                return (
                    <>
                        {record?.gatingPassed ? (
                            <img src={passIcon} alt="" />
                        ) : (
                            <img src={failIcon} alt="" />
                        )}
                    </>
                );
            },
        },
        {
            title: "Scorecard Available",
            dataIndex: "lenderScorecardAvailable",
            key: "lenderScorecardAvailable",
            align: "center",
            render: (_: any, record: any) => {
                return (
                    <>
                        {record?.lenderScorecardAvailable ? (
                            <img src={passIcon} alt="" />
                        ) : (
                            <img src={failIcon} alt="" />
                        )}
                    </>
                );
            },
        },
        {
            title: "Offer Decision Configuration",
            dataIndex: "lenderAvailableInOfferDecision",
            key: "lenderAvailableInOfferDecision",
            align: "center",
            render: (_: any, record: any) => {
                return (
                    <>
                        {record?.lenderAvailableInOfferDecision ? (
                            <img src={passIcon} alt="" />
                        ) : (
                            <img src={failIcon} alt="" />
                        )}
                    </>
                );
            },
        },
        {
            title: "Offer Calculation Configuration",
            dataIndex: "lenderOfferExcelPublished",
            key: "lenderOfferExcelPublished",
            align: "center",
            render: (_: any, record: any) => {
                return (
                    <>
                        {record?.lenderOfferExcelPublished ? (
                            <img src={passIcon} alt="" />
                        ) : (
                            <img src={failIcon} alt="" />
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <Tabs
                className="lender-sub-tabs"
                size="small"
                activeKey={decisioningnTabkey}
                onChange={(key) => { setDecisioningTabkey(key); setSelectedLenderExecutionDataList([]) }}
            >
                {leadData?.journeyConfigResponseList?.map((data: any) => {
                    let tmpFindData = descisioningDetail?.find((item: any) =>
                        item?.journeyId == data?.journeyId
                    );
                    return (
                        <Tabs.TabPane
                            key={data?.journeyId}
                            tab={data?.journeyName}
                        >
                            <div className="tab-Userdetails">
                                <Space className="userdetails">
                                    <p style={{ color: `${tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning ? "red" : ""}` }}>Date/Time: {tmpFindData?.executionTime}</p>
                                    {tmpFindData?.isDsaCaseUpdatedAfterExecutingDecisioning && (
                                        <Tooltip
                                            title="Something has been changed please edit the case"
                                            placement="topLeft"
                                            color="#1A3175"
                                        >
                                            <img src={info} style={{ marginBottom: "8px" }} />
                                        </Tooltip>
                                    )}
                                    <p>Executed By: {tmpFindData?.executedBy}</p>
                                </Space>
                            </div>
                            <Table
                                columns={columns}
                                dataSource={tmpFindData?.decisioningDataListResponseList ?? []}
                                size="small"
                                pagination={{ showSizeChanger: true }}
                                scroll={{ x: "max-content" }}
                            />
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>
        </>
    );
};

export default Decisioning;
