import { Input, Space, Tooltip, Typography } from "antd";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import { useEffect, useState } from "react";
import { getEnumList } from "../../../shared/Utility/Utility";
import { GrCircleInformation } from "react-icons/gr";
import SelectList from "../../../shared/component/Select/Select";
import passIcon from "../../../assets/images/pass.svg";
import failIcon from "../../../assets/images/fell.svg";
import errorIcon from "../../../assets/images/error.svg";
import warningIcon from "../../../assets/images/warning.svg";
import "./scoreCard.scss";

interface IDecisioningRule {
    data: any;
    actionMetadata: any;
    eligibleLenders: any;
    conditionMetadata: any;
    decisionData: any;
    conditionEvaluationResults: any;
    isCancel: boolean;
    setIsCancel: any;
    setScoreCardData: any;
    scorecardData: any;
    ruleIndex: number;
    setIsShowCancelBtn: any;
    isAddRuleClicked: any;
    setIsAddRuleClicked: any;
    setIsAddRule: any;
    onAddRule: (data: any) => void;
    setIsShowTest: any;
    isShowTest: any;
    offerCalculationLenderOutputResponses: any;
    setLoanOfferData: any;
    setIsLoanOffer: any;
    testRule: any;
    checkIsEdit: any;
    setCheckIsEdit: (data: boolean) => void;
    actionWidth: string;
    conditionWidth: string;
    dataFilterSearch: () => void;
}
const DecisioningRule: React.FC<IDecisioningRule> = (props: IDecisioningRule) => {
    const {
        data,
        eligibleLenders,
        actionMetadata,
        isCancel,
        setIsCancel,
        decisionData,
        conditionEvaluationResults,
        conditionMetadata,
        setScoreCardData,
        scorecardData,
        ruleIndex,
        setIsShowCancelBtn,
        setIsAddRuleClicked,
        isAddRuleClicked,
        setIsAddRule,
        onAddRule,
        isShowTest,
        setIsShowTest,
        offerCalculationLenderOutputResponses,
        setLoanOfferData,
        setIsLoanOffer,
        testRule,
        checkIsEdit,
        setCheckIsEdit,
        actionWidth,
        conditionWidth,
        dataFilterSearch
    } = props;
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (isEdit && isCancel && checkIsEdit) {
            setIsEdit(false);
            setIsCancel(false);
            setCheckIsEdit(false);
        }
    }, [isEdit && isCancel]);

    useEffect(() => {
        if (isAddRuleClicked && (ruleIndex == 0)) {
            setIsCancel(false);
            setIsEdit(true);
            dataFilterSearch();
        }
        else if (ruleIndex != 0) {
            setIsEdit(false)
        }
        if (isAddRuleClicked) {
            setCheckIsEdit(true)
        }
        setIsAddRuleClicked(false);
    }, [isAddRuleClicked]);

    const findOfferCalculationLender =
        offerCalculationLenderOutputResponses?.find(
            (item: any) => item?.ruleNameIdentifier == data?.ruleName
        );
    const renderRuleValue = () => {
        if (decisionData?.decisionType == "OFFER_DECISION") {
            let tmpValue = data?.ruleName?.split('-');
            if (isEdit) {
                return (
                    <div style={{ display: "flex", gap: "10px" }}>
                        <Tooltip
                            color="#1a2778"
                            placement="top"
                            title={
                                <div style={{ display: "grid" }}>
                                    To create rule:
                                    <span>1. Select a lender from drop down 1</span>
                                    <span>2. Select a lender from drop down 2</span>
                                    <span>
                                        3. Enter the range required for positive Offer
                                        Decision. Ex: If a minimum score of 7 is required
                                        for an offer, enter "7,10"
                                    </span>
                                </div>
                            }
                        >
                            <GrCircleInformation
                                style={{ marginTop: "12px", fontSize: "20px" }}
                            />
                        </Tooltip>
                        <SelectList
                            options={eligibleLenders}
                            optionFilterProp="children"
                            value={isAddRuleClicked ? "" : undefined}
                            defaultValue={tmpValue?.at(0)}
                            style={{ width: "250px" }}
                            onChange={(e: any) => {
                                const tmpData = [...scorecardData];
                                data.ruleName = `${e}-${tmpValue?.at(-1) ?? ""}`;
                                tmpData[ruleIndex] = data;
                                setScoreCardData(tmpData);
                            }}
                        />
                        <SelectList
                            options={getEnumList("DECISIONING_PURPOSE")}
                            defaultValue={tmpValue?.at(-1)}
                            value={isAddRuleClicked ? "" : undefined}
                            style={{ width: "150px" }}
                            onChange={(e: any) => {
                                const tmpData = [...scorecardData];
                                data.ruleName = `${tmpValue?.at(0) ?? ""}-${e}`;
                                tmpData[ruleIndex] = data;
                                setScoreCardData(tmpData);
                            }}
                        />
                    </div>
                )
            } else {
                const eligibleName = eligibleLenders?.find(
                    (item: any) => item?.key == tmpValue?.at(0)
                );
                const purpose = getEnumList("DECISIONING_PURPOSE")?.find(
                    (item: any) => item?.key == tmpValue?.at(-1)
                );
                return <span>{(eligibleName?.value ?? "") + " - " + (purpose?.value ?? "")}</span>
            }
        } else {
            if (isEdit) {
                return <Input
                    defaultValue={data?.ruleName}
                    value={isAddRuleClicked ? "" : undefined}
                    style={{ border: "1px solid #c7c7c7" }}
                    size="large"
                    onBlur={(e: any) => {
                        const tmpData = [...scorecardData];
                        data.ruleName = e?.target?.value;
                        tmpData[ruleIndex] = data;
                        setScoreCardData(tmpData);
                    }}
                />

            }
            return <span>{data?.ruleName}</span>
        }
    }

    const renderConditionValue = (item: any, index: number) => {
        let tmpValue = item?.conditionParamterValues;
        const findExpression = conditionMetadata?.find((con: any) => con?.conditionId == item?.conditionId);
        const isPass = conditionEvaluationResults?.some(
            (res: any) =>
                res?.ruleMetaDataId == ruleIndex &&
                res?.passedConditionResultData?.some(
                    (passed: any) =>
                        passed?.conditionMetadataId == item?.conditionId
                )
        );
        const renderTest = (value: string) => (
            <>
                {isShowTest && (
                    <img
                        src={
                            isPass || (value ?? "") == ""
                                ? passIcon
                                : failIcon
                        }
                        alt=""
                    />
                )}
            </>
        )
        if (findExpression?.expression?.trim()?.includes('validatePincodeByLender')) {
            const val = item?.conditionParamterValues?.split(',');
            if (isEdit) {
                return (
                    <SelectList
                        style={{ width: "250px" }}
                        defaultValue={val?.at(0)}
                        value={isAddRuleClicked ? "" : undefined}
                        options={eligibleLenders}
                        optionFilterProp="children"
                        onChange={(e: any) => {
                            const tmpData = [...scorecardData];
                            data.decisioningConditions[index].conditionParamterValues = e;
                            tmpData[ruleIndex] = data;
                            setScoreCardData(tmpData);
                        }}
                    />
                )
            } else {
                const findLenderName = eligibleLenders?.find(
                    (item: any) => item?.key == val?.at(0)
                );
                return (
                    <div className="content-sb">
                        <span>{findLenderName?.value ?? ""}</span>
                        {renderTest(findLenderName?.value ?? "")}
                    </div>
                )
            }
        } else if (decisionData?.decisionType == "OFFER_DECISION") {
            const val = item?.conditionParamterValues?.split(',');
            val?.splice(0, 2);
            tmpValue = val?.join(",")
            if (isEdit) {
                return <Input
                    defaultValue={tmpValue}
                    value={isAddRuleClicked ? "" : undefined}
                    style={{ border: "1px solid #c7c7c7" }}
                    size="large"
                    onBlur={(e: any) => {
                        const tmpData = [...scorecardData];
                        data.decisioningConditions[index].conditionParamterValues = `${data?.ruleName?.replaceAll("-", ",")},${e?.target?.value}`
                        tmpData[ruleIndex] = data;
                        setScoreCardData(tmpData);
                    }}
                />

            }
            return (
                <div className="content-sb">
                    <span>{tmpValue}</span>
                    {renderTest(tmpValue)}
                </div>
            )
        } else {
            if (isEdit) {
                return <Input
                    defaultValue={tmpValue}
                    value={isAddRuleClicked ? "" : undefined}
                    style={{ border: "1px solid #c7c7c7" }}
                    size="large"
                    onBlur={(e: any) => {
                        const tmpData = [...scorecardData];
                        data.decisioningConditions[index].conditionParamterValues = `${e?.target?.value}`
                        tmpData[ruleIndex] = data;
                        setScoreCardData(tmpData);
                    }}
                />

            }
            return (
                <div className="content-sb">
                    <span>{tmpValue}</span>
                    {renderTest(tmpValue)}
                </div>
            )
        }
    }

    const renderActionValue = (item: any, index: number) => {
        const configArr: any = [
            {
                key: "TRUE",
                value: "True",
            },
            {
                key: "FALSE",
                value: "False",
            },
        ];
        const findAct = actionMetadata?.find((act: any) => act?.actionId == item?.actionId);
        const displayNameListData = findAct?.categoryInfoList?.map((item: any) => {
            return {
                key: item.id,
                value: `${item.categoryName},${item?.weightage}%`,
            };
        });
        if (decisionData?.decisionType != "OFFER_DECISION") {
            const isCategoryInfoList =
                (findAct?.categoryInfoList?.length ?? 0) > 0;
            let categoryName = displayNameListData?.find(
                (item1: any) =>
                    item1?.key == item?.categoryId
            );
            if (isEdit) {
                return (
                    <Space>
                        {isCategoryInfoList && (
                            <SelectList
                                style={{ width: "250px" }}
                                options={displayNameListData}
                                defaultValue={categoryName?.value}
                                value={isAddRuleClicked ? "" : undefined}
                                onChange={(e: any) => {
                                    const tmpData = [...scorecardData];
                                    data.decisioningActions[index].categoryId = e;
                                    tmpData[ruleIndex] = data;
                                    setScoreCardData(tmpData);
                                }}
                            />
                        )}
                        {(findAct?.expression == "SET_CONDITIONAL_CONFIG_VALUE" || findAct?.expression == "ELIGIBLE_LENDERS") ? (
                            <SelectList
                                style={{ width: "250px" }}
                                options={findAct?.expression == "SET_CONDITIONAL_CONFIG_VALUE" ? configArr : eligibleLenders}
                                defaultValue={item?.actionParamterValues}
                                value={isAddRuleClicked ? "" : undefined}
                                onChange={(e: any) => {
                                    const tmpData = [...scorecardData];
                                    data.decisioningActions[index].actionParamterValues = e;
                                    tmpData[ruleIndex] = data;
                                    setScoreCardData(tmpData);
                                }}
                            />
                        ) : <Input
                            size="large"
                            defaultValue={isCategoryInfoList ? item?.scoreOrReject : item?.actionParamterValues}
                            value={isAddRuleClicked ? "" : undefined}
                            style={{ border: "1px solid #c7c7c7", width: isCategoryInfoList ? "50px" : "200px" }}
                            onBlur={(e: any) => {
                                const tmpData = [...scorecardData];
                                if (isCategoryInfoList) {
                                    data.decisioningActions[index].scoreOrReject = e?.target?.value;
                                } else {
                                    data.decisioningActions[index].actionParamterValues = e?.target?.value;
                                }
                                tmpData[ruleIndex] = data;
                                setScoreCardData(tmpData);
                            }}
                        />}
                    </Space>
                )
            } else {
                if (findAct?.expression == "SET_CONDITIONAL_CONFIG_VALUE") {
                    let configName = configArr?.find(
                        (item1: any) => item1?.key == item?.actionParamterValues
                    );
                    return <span>{configName?.value}</span>;
                } else if (findAct?.expression == "ELIGIBLE_LENDERS") {
                    let eligibleName = eligibleLenders?.find(
                        (item1: any) => item1?.key == item?.actionParamterValues
                    );
                    return <span>{eligibleName?.value}</span>;
                } else {
                    const cat = categoryName?.value
                        ? `${categoryName?.value},${item?.scoreOrReject}`
                        : "";
                    return <span>{isCategoryInfoList ? cat : item?.actionParamterValues}</span>;
                }
            }
        }
    };

    return (
        <Space className="list-space" style={{ minWidth: "100%", border: "1px solid #c9c7c561", borderLeft: "none", borderRight: "none", borderBottom: "none" }}>
            <div style={{ width: (decisionData?.decisionType == "OFFER_DECISION" && checkIsEdit) ? "360px" : "185px", padding: "5px", textOverflow: "ellipsis", wordBreak: "break-all" }}>
                {renderRuleValue()}
            </div>
            {data?.decisioningConditions?.map((item: any, index: number) => {
                return (
                    <div style={{ width: (decisionData?.decisionType == "OFFER_DECISION" && checkIsEdit) ? "310px" : `${conditionWidth}px`, textOverflow: "ellipsis", wordBreak: "break-all", padding: "5px" }}>
                        {
                            renderConditionValue(item, index)
                        }
                    </div>
                )
            })}
            {data?.decisioningActions?.map((item: any, index: number) => {
                return (
                    <div style={{ width: `${actionWidth}px`, padding: "5px" }}>
                        {
                            renderActionValue(item, index)
                        }
                    </div>
                )
            })}
            <div style={{ minWidth: "150px", maxWidth: "200px", display: "flex", justifyContent: "center", background: "white" }}>
                {isShowTest && (
                    <>
                        {(offerCalculationLenderOutputResponses?.length ?? 0) > 0 &&
                            findOfferCalculationLender && (
                                <Tooltip
                                    title={
                                        findOfferCalculationLender?.errorDescriptionOnProcessing
                                    }
                                >
                                    <img
                                        style={{
                                            cursor: "pointer",
                                            marginTop: "2px",
                                            marginRight: "2px",
                                        }}
                                        src={
                                            findOfferCalculationLender?.loanOffer
                                                ? warningIcon
                                                : errorIcon
                                        }
                                        alt=""
                                        onClick={() => {
                                            setLoanOfferData(
                                                findOfferCalculationLender?.loanOffer ?? {}
                                            );
                                            if (findOfferCalculationLender?.loanOffer) {
                                                setIsLoanOffer(true);
                                            }
                                        }}
                                    />
                                </Tooltip>
                            )
                        }
                        <img
                            src={
                                testRule?.includes(data?.ruleName?.trim())
                                    ? passIcon
                                    : failIcon
                            }
                            alt=""
                        />
                    </>
                )}
                {!isEdit &&
                    <FormIconButton type='editBlack'
                        onClick={() => {
                            setCheckIsEdit(true);
                            setIsShowCancelBtn(true);
                            setIsEdit(true);
                            setIsShowTest(false);
                        }}
                    />
                }
                <FormIconButton
                    isShowPopconfirm
                    type="delete"
                    onClick={() => {
                        let tmpArray = scorecardData;
                        tmpArray.splice(ruleIndex, 1);
                        setScoreCardData(tmpArray);
                        onAddRule(tmpArray)
                        setIsEdit(false);
                        setCheckIsEdit(false);
                        setIsAddRule(false);
                    }}
                />
            </div>
        </Space>
    )
};
export default DecisioningRule;