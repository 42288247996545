import { Form, Upload, UploadProps, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { fileUploadCommon } from "../../Utility/Utility";

type fileType = "image" | "pdf" | "xls" | "xlsx" | "svg" | "json";

interface IAppUpload {
  acceptType?: fileType[];
  businessId?: string; // If available then upload document API calling here
  callBack: any;
  className?: string;
  multiple?: boolean;
  name?: string;
  maxSize?: number;
  showUploadList?: boolean;
  children: React.ReactNode;
  setImageUrl?: any;
  isCallBackFile?: boolean;
  clearFileList?: boolean;
}

const AppUpload: React.FC<IAppUpload> = (props: IAppUpload) => {
  const {
    name,
    maxSize,
    className,
    acceptType,
    showUploadList,
    multiple,
    children,
    businessId,
    callBack,
    setImageUrl,
    isCallBackFile,
    clearFileList,
  } = props;

  const [fileList, setFileList] = useState([] as any);

  useEffect(() => {
    if (clearFileList) {
      setFileList([]);
    }
  }, [clearFileList]);

  const getAccept = () => {
    let fileFormat: string[] = [];
    if (acceptType?.includes("pdf")) {
      fileFormat.push("application/pdf");
    }
    if (acceptType?.includes("json")) {
      fileFormat.push("application/json");
    }
    if (acceptType?.includes("xls") || acceptType?.includes("xlsx")) {
      fileFormat.push("application/vnd.ms-excel");
      fileFormat.push(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    }
    if (acceptType?.includes("svg")) {
      fileFormat.push("image/svg+xml");
    }
    if ((acceptType?.length ?? 0) == 0 || acceptType?.includes("image")) {
      fileFormat.push("image/jpg");
      fileFormat.push("image/jpeg");
      fileFormat.push("image/png");
    }
    return fileFormat;
  };

  const onUpload = useCallback((acceptedFile: any) => {
    if (acceptedFile?.file?.status !== "removed") {
      if (acceptedFile?.file?.size > (maxSize ?? 10) * 1024 * 1024) {
        notification.error({
          message: `Please attach less than ${maxSize ?? 10}MB file`,
        });
        setFileList([]);
        return;
      } else {
        const allowedAttechmentTypes = getAccept();
        console.log("allowedAttechmentTypes: ", allowedAttechmentTypes);
        if (allowedAttechmentTypes.indexOf(acceptedFile?.file?.type) === -1) {
          const msgType = acceptType?.join(", ");
          notification.error({
            message: `Please attach only ${msgType ?? "image"} file.`,
          });
          return;
        }
        UploadDocumentHandler(acceptedFile?.file);
        if (acceptedFile?.file.type !== "application/json") {
          setImageUrl(acceptedFile?.fileList[0]?.originFileObj);
        }
      }
    }
  }, []);

  const uploadProps: UploadProps = {
    accept: getAccept().join(", "),
    listType: "text",
    showUploadList: showUploadList ?? false,
    multiple: multiple,
    maxCount: 1,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const UploadDocumentHandler = async (file: any) => {
    if (isCallBackFile) {
      callBack(file);
    } else {
      const response = await fileUploadCommon(file);
      if (response?.data && response?.file) {
        if ((businessId?.length ?? 0) > 0) {
          // uploadDocument(response?.file, response?.data);
        } else {
          callBack(response);
        }
      }
    }
  };

  return name ? (
    <>
      <Form.Item name={name}>
        <Upload
          className={className ?? ""}
          onChange={onUpload}
          {...uploadProps}
        >
          {children}
        </Upload>
      </Form.Item>
    </>
  ) :
    <Upload
      className={className ?? ""}
      onChange={onUpload}
      {...uploadProps}
    >
      {children}
    </Upload>
    ;
};
export default AppUpload;
