import React, { useEffect, useState } from 'react';
import { Form, Space, Typography } from 'antd';
import FormInput from '../../../shared/component/FormInput/FormInput';
import FormButton from '../../../shared/component/FormButton/FormButton';
import FormTextArea from '../../../shared/component/FormTextArea/FormTextArea';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../shared/redux/alert-reducer';
import { API_SERVICE } from '../../../shared/services/api-services';
import FormSelect from '../../../shared/component/FormSelect/FormSelect';
import { debounce } from 'lodash';
import "../properties.scss"

const { Title } = Typography;

interface IAddCollection {
    setAddCollection: any;
    getCollectionList: any;
    editCollectionData: any;
    isEdit: boolean;
}

const AddCollection: React.FC<IAddCollection> = (props: IAddCollection) => {

    const { setAddCollection, getCollectionList, editCollectionData, isEdit } = props;
    const dispatch = useDispatch();
    const [collectionForm] = Form.useForm() as any;
    const [nextStage, setNextStage] = useState(false);
    const [showCollection, setShowCollection] = useState(true);
    const [objectList, setObjectList] = useState([]);
    const [getTextAreaValue, setGetTextAreaValue] = useState("");

    useEffect(() => {
        if (isEdit) {
            collectionForm.setFieldsValue({
                name: editCollectionData?.name,
                description: editCollectionData?.description,
                propertyList: editCollectionData?.properties.map((data: any) => {
                    return data.id
                })
            })
        };
    }, []);

    const getPropertyList = (name: any = null) => {
        const param = { name: name, pageSize:100 }
        API_SERVICE.getPropertyList(param)
            .then(({ data }) => {
                const displayNameListData = data.payload.content?.map((item: any) => {
                    return { key: item.id, value: item.displayName };
                });
                setObjectList(displayNameListData)
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    }

    const createCollection = (payload: any) => {
        API_SERVICE.createCollection(payload)
            .then(({ data }) => {
                setAddCollection(false);
                getCollectionList();
                dispatch(setAlertMsg({ message: 'Collection was created successfully.', description: 'Collection add in collection-list.', showAlertMessage: true }));
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    };

    const updateCollection = (payload: any) => {
        const id = editCollectionData?.id
        API_SERVICE.updateCollection(id, payload)
            .then(({ data }) => {
                setAddCollection(false);
                getCollectionList();
                dispatch(setAlertMsg({ message: 'Collection was updated successfully.', description: 'Collection update in collection-list.', showAlertMessage: true }));
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    }

    const onFinish = (values: any) => {
        setNextStage(true);
        getPropertyList();
        if (nextStage) {
            onFinalClick(values)
        }
    };

    const onFinalClick = (values: any) => {
        setAddCollection(false)
        setShowCollection(false)
        const payload = {
            "name": values?.name,
            "description": getTextAreaValue,
            "properties": values?.propertyList,
        }
        if (isEdit) {
            updateCollection(payload);
        } else {
            createCollection(payload);
        }
    }

    const handleListSearch = (record: any) => {
        console.log(record, 'record')
        getPropertyList(record);
    }

    const renderShowCollection = () => (
        <div className={"layout-main"} style={{ marginTop: "15px" }}>
            <Title level={5} style={{ padding: "17px" }}>
                {`${isEdit ? "EDIT" : "CREATE A NEW"} COLLECTION`}
            </Title>
            <div className="drawer-layout" >
                <div className="drawer-form">
                    <FormInput
                        label="Name"
                        classNameForm="space-input"
                        isBorder={true}
                        name="name"
                        placeholder="Enter Name"
                        isRequired
                        requiredLabel="name"
                    />
                    {!nextStage ?
                        <FormTextArea
                            label="Description"
                            classNameForm="space-input"
                            isBorder={true}
                            name="description"
                            placeholder="Enter Description"
                            onChange={(e: any) => { setGetTextAreaValue(e.target.value) }}
                        /> :
                        <FormSelect
                            label='Property'
                            name="propertyList"
                            optionFilterProp="children"
                            classNameForm='space-input'
                            placeholder="Property List"
                            isBorder={true}
                            mode="multiple"
                            maxTagCount="responsive"
                            options={objectList}
                            isRequired
                            onSearch={debounce(handleListSearch, 1000)}
                        />}
                    <Space className='step-button'>
                        <FormButton
                            size="large"
                            label={!nextStage ? "Cancel" : "Back"}
                            type='default'
                            onclick={() => { !nextStage ? setAddCollection(false) : setNextStage(false) }}
                        />
                        <FormButton
                            size="large"
                            label={!nextStage ? "Next" : "Save"}
                            type="primary"
                            htmlType="submit"
                        />
                    </Space>
                </div>
            </div>
        </div>
    );

    return (
        <Form
            form={collectionForm}
            layout="vertical"
            onFinish={onFinish}
        >
            {showCollection && (
                renderShowCollection()
            )}
        </Form>
    );
};

export default AddCollection;