import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScreenTitle } from "../../../shared/redux/common-reducer";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import LeadDetailNew from "../LeadDetail/LeadDetail";
import { API_SERVICE } from "../../../shared/services/api-services";
import { RootState } from "../../../shared/redux/store";
import { numberWithCommas } from "../../../shared/Utility/Utility";
import ViewDocument from "../../../shared/component/ViewDocument/ViewDocument";
import { setInvoiceId, setInvoicePageNo, setInvoicePageSize, setInvoiceTenantId } from "../../../shared/redux/invoice-reducer";
type Props = {};

interface IInvoiceDetails {
    setIsLoading: (value: boolean) => void;
    setViewInvDisbursal:(value: boolean) => void;
    setOpenLeadDetail:(value:boolean) => void
    }

const InvoiceDetails: React.FC<IInvoiceDetails> = (props: IInvoiceDetails) => {
    const { setIsLoading, setViewInvDisbursal, setOpenLeadDetail} = props;
    const [openLeadDetails, setOpenLeadDetails] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [disbursalListData, setDisbursalListData] = useState([] as any);
    const [caseId, setCaseId] = useState("");
    const [isViewDoc, setIsViewDoc] = useState(false);
    const [viewDocumentId, setViewDocumentId] = useState("");
    
    const { pageNo, pageSize, invoiceTenantId ,invoiceId} = useSelector(
        (state: RootState) => state.invoice
      );
    const dispatch = useDispatch();

    useEffect(() => {
        getDisbursalList()
    }, [])

    const getDisbursalList = (
        pageNo: number = 1,
        pageSize: number = 10
    ) => {
        const params = {
            pageNo: pageNo,
            pageSize: pageSize,
            tenantBusinessInfoIds: invoiceTenantId,
            invoiceId: invoiceId
        };
        API_SERVICE.getIncompleteDisbursalList(params)
            .then(({ data }) => {
                setDisbursalListData(data?.payload?.content);
                setTotalCount(data?.payload?.totalElements)
            }).catch((e: any) =>
                API_SERVICE.handleErrors(e)
            ).finally(() => setIsLoading(false))
    }

    const handleViewLeadDetail = (rec: any) => {
        setCaseId(rec?.caseId)
        // setOpenLeadDetail(true);
        setOpenLeadDetails(true)
        dispatch(setScreenTitle("Invoices > Disbursals > View Lead"))
    }

    const column: ColumnsType<any> = [
        {
            title: "Case ID",
            dataIndex: "caseId",
            key: "caseId",
        },
        {
            title: "Disbursal Id",
            dataIndex: "disbursalId",
            key: "disbursalId",
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            key: "customerName",
            width: 180
        },
        {
            title: "Product Type",
            dataIndex: "productType",
            key: "productType",
        },
        {
            title: "Lender",
            dataIndex: "lenderName",
            key: "lender",
            width: 150
        },
        {
            title: "Disbursal Amount",
            dataIndex: "disbursedAmount",
            key: "disbursalAmount",
            render: (value) => (
                <span>Rs. {numberWithCommas(value)}</span>
            )
        },
        {
            title: "Disbursal Date",
            dataIndex: "disbursalDate",
            key: "disbursalDate",
        },
        {
            title: "Lender Identifier",
            dataIndex: "lenderUniqueIdentifier",
            key: "uniqueIdentifier",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            width: 100,
            render: (val, rec) => (
                <>
                    <FormIconButton
                        type="eye"
                        onClick={() => {
                            if (rec?.proofOfDisbursal?.documentIdList?.at(0)) {
                                setViewDocumentId(rec?.proofOfDisbursal?.documentIdList?.at(0));
                                setIsViewDoc(true);
                            }
                        }}
                        tooltipTitle="Proof of Disbursal"
                    />
                    <FormIconButton
                        type="drillDown"
                        onClick={() => handleViewLeadDetail(rec)}
                        tooltipTitle="View Lead"
                    />
                </>
            )
        },
    ];

    const handlePaginationChange = (pagination:any) => {
        let page = pagination.pageSize !== pageSize ? 1 : pagination.current;
        dispatch(setInvoicePageNo(page));
        dispatch(setInvoicePageSize(pagination.pageSize));
        getDisbursalList(pageNo, pageSize);
    }

    const renderLeadDetail = () => (
        <LeadDetailNew caseId={caseId} 
            setOpenLeadDetail = {setOpenLeadDetails}
            setViewInvDisbursal={setViewInvDisbursal}
        />
    )


    return (
        <>            
            <div className={"layout-main"} style={{ marginTop: "20px" }}>
                {openLeadDetails ? renderLeadDetail() :
                <>
                    <div style={{ marginBottom: "20px" }}>
                        <Button
                         type="link"
                         style={{
                            float:"right"
                         }}
                         onClick={() => {
                            setViewInvDisbursal(false);      
                            dispatch(setScreenTitle("Invoice"))                     
                         }}>
                            Back to Invoice
                        </Button>
                    </div>
                
                    <Table
                        columns={column}
                        dataSource={disbursalListData}
                        size="small"
                        pagination={{
                            position: ["bottomCenter"],
                            current:  pageNo,
                            pageSize: pageSize,
                            total: totalCount
                        }}
                        onChange={handlePaginationChange}
                    />
                    </>
                }
            </div>
            {isViewDoc && (
                <ViewDocument
                    showPopDocument={isViewDoc}
                    setIsShowPopDocument={setIsViewDoc}
                    businessDocumentId={viewDocumentId}
                />
            )}
        </>
    )
}

export default InvoiceDetails;