import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const invoiceSlice = createSlice({
  name: "invoiceReducer",
  initialState: { pageNo: 1, pageSize: 10, invoiceTenantId: null, invoiceId: null, },
  reducers: {
    setInvoicePageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setInvoicePageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setInvoiceTenantId: (state, action: PayloadAction<any>) => {
      state.invoiceTenantId = action.payload;
    },
    setInvoiceId: (state, action: PayloadAction<any>) => {
        state.invoiceId = action.payload;
    },
  },
});

export const { setInvoicePageNo, setInvoicePageSize, setInvoiceTenantId, setInvoiceId } = invoiceSlice.actions;
export default invoiceSlice.reducer;
