import React, { useEffect, useState } from "react";
import { Tabs,Tooltip } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import FormSelect from "../../shared/component/FormSelect/FormSelect";
import { API_SERVICE } from "../../shared/services/api-services";
import Property from "./Property/Property";
import Collection from "./Collection/Collection";
import "./properties.scss";
import DrawerFile from "./Property/AddProperty/DrawerFile";
import AddCollection from "./Collection/AddCollection";
import ObjectTemplate from "./ObjectTemplate/ObjectTemplate";
import AddObjectTemplate from "./ObjectTemplate/AddObjectTemplate"
import SubProperty from "./Property/SubProperty";
import { InfoCircleOutlined } from '@ant-design/icons';

const Properties: React.FC = () => {


  const [hideEnterProperty, sethideEnterProperty] = useState(true);
  const [addSubId, setaddSubId] =  useState(0);
  const [objectList, setObjectList] = useState([])
  const [selectedObject, setSelectedObject] = useState(null as any);
  const [tabKey, setTabKey] = useState("PROPERTY");
  const [editPropertyData, setEditPropertyData] = useState(null as any);
  const [addCollection, setAddCollection] = useState(false);
  const [editCollectionData, setEditCollectionData] = useState(null as any);
  const [isEdit, setIsEdit] = useState(false);
  const[addSubName,setAddSubName]=useState(" ");
  const [showValue, setShowValue] = useState(false);
  const [addObjectTemplate, setAddObjectTemplate] = useState(
    [] as any
  );
  const [PassObjectForName,setPassObjectForName] =  useState(null);

  const [showAddPropery, setShowAddPropery] = useState(false);
const [specificValue, setSpecificValue] = useState(null);

const handleShowAddProperty = (show: boolean, value: any) => {
    setShowAddPropery(show);
    setSpecificValue(value);
};

  useEffect(() => {
    getObjectList()
  }, []);

  const getObjectList = (name: any = null) => {
    const params = { type: "OBJECT", name: name };
    API_SERVICE.getPropertyList(params)
      .then(({ data }: any) => {
        const displayNameListData = data.payload.content?.map((item: any) => {
          return { key: item.id, value: item.displayName };
        });
        setObjectList(displayNameListData);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
  };

  const onChangePropertyType = (id: any) => {
    setSelectedObject(id);
  }

  const onSearchPropertyType = (record: any) => {
    getObjectList(record);
  }

  return (
    <>
      {showAddPropery ?
        <DrawerFile
          setShowAddPropery={setShowAddPropery}
          setSpecificValue={setSpecificValue}
          specificValue={specificValue}
          setShowValue= {setShowValue}
          setPassObjectForName={setPassObjectForName}
          editPropertyData={editPropertyData}
          setEditPropertyData={setEditPropertyData}
          getPropertyList={() => { }}                  
        />
        :
        addCollection ?
          <AddCollection
            setAddCollection={setAddCollection}
            getCollectionList={() => { }}
            editCollectionData={editCollectionData}
            isEdit={isEdit}
          />
          :
          showValue ?
          <AddObjectTemplate
          setAddObjectTemplate={setAddObjectTemplate}
          addObjectTemplate= {addObjectTemplate}
          setShowValue= {setShowValue}
          showValue={showValue}
          setShowAddPropery={setShowAddPropery}
          handleShowAddProperty={handleShowAddProperty}
          PassObjectForName={PassObjectForName}
              getObjectTemplateList={() => { } }
              editObjectTemplateData={editCollectionData}
              isEdit={isEdit} />
          :
          (<>
            {/* {tabKey === "PROPERTY" && hideEnterProperty ? (
              <div className={"layout-main"} style={{ minHeight: "70px", marginTop: "20px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ padding: "5px" }}>Select an Object:</span>
                  <div>
                    <FormSelect
                      name="propertyType"
                      optionFilterProp="children"
                      placeholder="Enter Property Type"
                      // classNameForm="space-input"
                      options={objectList}
                      onChange={onChangePropertyType}
                      onSearch={onSearchPropertyType}
                      isBorder
                    />
                  </div>
                </div>
              </div>
            ) : null} */}

            
            <div className={"layout-main"} style={{ marginTop: "20px" }}>
              { hideEnterProperty ? (
              <div style={{ padding: "10px" }}>
                <Tabs activeKey={tabKey} onChange={(key: any) => setTabKey(key)}>
                  <TabPane tab="PROPERTY" key="PROPERTY">
                    <Property
                      selectedObject={selectedObject}
                      setEditPropertyData={setEditPropertyData}
                      setShowAddPropery={setShowAddPropery}     
                      hideEnterProperty={(key: any)=>sethideEnterProperty(key)}
                      addIdValue={(value: any)=>setaddSubId(value)}
                      setAddSubName={setAddSubName}       
                    />
                  </TabPane>
                  <TabPane tab="COLLECTION" key="COLLECTION" >
                    <Collection
                      setAddCollection={setAddCollection}
                      setEditCollectionData={setEditCollectionData}
                      setIsEdit={setIsEdit}
                    />
                  </TabPane>
                  <TabPane  tab={
                      <div>
                        OBJECT TEMPLATE
                        <Tooltip title="Object Template can be used for the creation of Object.">
                          <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                        </Tooltip>
                      </div>
                    } key="OBJECTTEMPLATE" >
                    <ObjectTemplate
                      setAddObjectTemplate={setAddObjectTemplate}
                      setEditCollectionData={setEditCollectionData}
                      setShowValue= {setShowValue}
                      setIsEdit={setIsEdit}

                    />
                  </TabPane>
                </Tabs>
               
              </div>
              ): (
                <SubProperty  addSubName={addSubName} addSubId={addSubId} setShowAddPropery={setShowAddPropery} sethideEnterProperty={sethideEnterProperty} setEditPropertyData={setEditPropertyData} />
              )}
            </div>
          </>)}
    </>
  );
};

export default Properties;
