import { Form, Spin, Typography, message, notification } from "antd";
import { useState,useRef } from "react";
import logo from "../../assets/images/logo.svg";
import FormInput from "../../shared/component/FormInput/FormInput";
import FormButton from "../../shared/component/FormButton/FormButton";
import { API_SERVICE } from "../../shared/services/api-services";
import { AuthConsumer } from "../../shared/contexts/AuthContext";
import "./SignIn.scss";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
const { Title } = Typography;

const SignIn = () => {
  const [captchaVisible,setCaptchaVisible]=useState<boolean>(false);
  const [attempts,setAttempts]=useState<number>(0);
  const[email,setEmail]=useState<string|null>(null);
  const [captchacompleted,setCaptchacompleted]=useState<boolean>(false);
  const recapchaRef=useRef<ReCAPTCHA|null>(null);
  const [reCaptchaToken, setReCaptchaToken] = useState("") as any
  const [loading, setLoading] = useState(false);


   function handleCaptcha(email:any){
    let REGEX=/^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if( REGEX.test(email))
    {
      setReCaptchaToken("")
     
      const payload = {
        email: email,
        
        reCaptchaScreenName:"SIGN_IN"
      }
      //console.log(payload)
      setCaptchaVisible(false);
      setLoading(true);
      API_SERVICE.checkCapchaRequired(payload)
      
     .then(({ data }) => {
      console.log("data",data)
      if (data.payload.captchaRequired===true) {
        setCaptchaVisible(true);
      }
       
    })
    .catch(function (e) {
     // API_SERVICE.handleErrors(e);
    }) 
    .finally(() => setLoading(false));
  }
}
  const onSubmit = (values: any, updateAPICreds: any) => {
    
    console.log("value--", values);
     
    const payload = {
      email: values.email,
      mobileNumber: "",
      password: values.password,
      userId: "",
      reCaptchaScreenName:"SIGN_IN"
    };
    if((reCaptchaToken?.length === 0 && captchaVisible)){
      notification.error({
        message: "reCaptcha is not checked",
      });
    }
    else{setLoading(true);
    API_SERVICE.login(payload)
      .then(({ data }) => {
        if ((!data?.payload?.userDetails?.allUserRoles?.includes("ROLE_ADMIN")) && (!data?.payload?.userDetails?.allUserRoles?.includes("ROLE_ACCOUNTANT"))) {
          throw `User with: ${values.email} not allowed/permitted to login`;
        }
        localStorage.setItem("user", JSON.stringify(data?.payload));
        updateAPICreds(data);
        getEnumTypes();
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
        handleCaptcha(values.email)
        
      })
      .finally(()=>setLoading(false))
       
      
    }
  };

  const getEnumTypes = () => {
    API_SERVICE.getTypes()
      .then(({ data }) => {
        localStorage.setItem("enumValues", JSON.stringify(data));
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const renderView = (updateAPICreds: any) => {
    return (
      <div className="login-card">
        <Form
          layout="vertical"
          onFinish={(values: any) => {
            onSubmit(values, updateAPICreds);
          }}
        >
          <div className="logo-style">
            <img src={logo} />
          </div>
          <Title className="logo-style"> Sign in </Title>

          <FormInput
            label="Enter Email"
            isBorder={true}
            name="email"
            placeholder="Enter Email"
            requiredLabel="email"
            onChange={(e)=>handleCaptcha(e.target.value)}
            isRequired
            rules={[{ type: "email", message: "Please enter valid email" }]}
          />
          <FormInput
            label="Password"
            classNameForm="space-input"
            isBorder={true}
            name="password"
            placeholder="Enter password"
            requiredLabel="password"
            type="password"
            isRequired
          />
          <Link
            style={{ display: "flex", justifyContent: "end", padding: "5px" }}
            to={"/forgot-password"}
          >
            Forgot password ?
          </Link>
 
          {captchaVisible && 
           <div>  <ReCAPTCHA sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" ref={recapchaRef}    onChange={(token)=>setReCaptchaToken(token)} /></div>


          }

         
          <FormButton
            size="large"
            label="Login"
            type="primary"
            htmlType="submit"
            loading={loading}
            
          />
        </Form>
      </div>
    );
  };

  return (
    <AuthConsumer>
      {({ isAuth, updateAPICreds, permissions }) => {
        return isAuth ? <></> : renderView(updateAPICreds);
      }}
    </AuthConsumer>
  );
};

export default SignIn;
