import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Collapse, Divider, Form, Space, Typography,Tooltip  } from 'antd';
import FormInput from '../../../shared/component/FormInput/FormInput';
import FormButton from '../../../shared/component/FormButton/FormButton';
import FormTextArea from '../../../shared/component/FormTextArea/FormTextArea';
import { useDispatch } from 'react-redux';
import { setAlertMsg } from '../../../shared/redux/alert-reducer';
import { API_SERVICE } from '../../../shared/services/api-services';
import FormSelect from '../../../shared/component/FormSelect/FormSelect';
import { InfoCircleOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import { Name_REGEX } from '../../../shared/AppConstants';
import "../properties.scss"


const { Title } = Typography;

interface IAddObjectTemplate {
    setAddObjectTemplate: any;
    getObjectTemplateList: any;
    editObjectTemplateData: any;
    addObjectTemplate: any;
    isEdit: boolean;
    setShowValue: any;
    setShowAddPropery:any;
    handleShowAddProperty:any;
    showValue:any;
    PassObjectForName:any;
}
  const { Panel } = Collapse;
  
const AddObjectTemplate: React.FC<IAddObjectTemplate> = (props: IAddObjectTemplate) => {

    const { setAddObjectTemplate,showValue,PassObjectForName,setShowValue,setShowAddPropery,handleShowAddProperty, getObjectTemplateList, editObjectTemplateData, isEdit,addObjectTemplate = [] } = props;

    const [propertyList, setPropertyList] = useState([] as any);
    const [addObjectTemplateForValue, setAddObjectTemplateForValue] = useState([] as any);
    const [simpleChecked,setSimpleChecked] = useState([] as any);
    const [complexChecked,setComplexChecked] = useState([] as any);
    const dispatch = useDispatch();
    const [objectForm] = Form.useForm() as any;
    const [nextStage, setNextStage] = useState(false);
    const [showCollection, setShowCollection] = useState(true);
    const [objectList, setObjectList] = useState([]);
    const [getTextAreaValue, setGetTextAreaValue] = useState("");
    
    const [showvalue, setshowvalue] = useState(false);

    useEffect(() => {
        if (isEdit) {
            objectForm.setFieldsValue({
                name: editObjectTemplateData?.name,
                description: editObjectTemplateData?.description,
                propertyList: editObjectTemplateData?.properties.map((data: any) => data.id)
            });
            //setAddObjectTemplate(editObjectTemplateData?.properties.map((data: any) => data.id) || []);

            const selectedPropertiesList = editObjectTemplateData?.properties || [];

            const selectedKeys = selectedPropertiesList.map((prop: any) => prop.id);
            const selectedValues = selectedPropertiesList.map((prop: any) => prop.displayName);
            setAddObjectTemplateForValue(selectedValues);
            setSimpleChecked(selectedKeys);
        }

        if (PassObjectForName) {
            const matchingProperties = propertyList
                .filter((property: any) => property.value === PassObjectForName)
                .map((property: any) => property.key);
            
            setAddObjectTemplate((prevTemplate: any) => [...prevTemplate, ...matchingProperties]);
        }

    }, [isEdit, editObjectTemplateData, objectForm, PassObjectForName, propertyList]);

    useEffect(() => {
        masterPropertyList();
      },[]);

      const masterPropertyList = (searchParam: any = null) => {
        const params = {
          name: searchParam,
          pageSize: 100,
        };
        API_SERVICE.getPropertyList(params)
          .then(({ data }) => {
            const displayNameList = data?.payload?.content?.map((item: any) => {
              return {
                key: item?.id,
                value: item?.displayName,
                children: item?.children,
              };
            });
            setPropertyList(displayNameList);
            if (isEdit) {
                getProductPropertyList();
            }
          })
          .catch((e: any) => API_SERVICE.handleErrors(e));
      };

      const getProductPropertyList = () => {
        const id = editObjectTemplateData?.id
        API_SERVICE.getObjectProperty(id)
          .then(({ data }) => {
            const simple = data?.payload?.properties?.map((item: any) => {
              return item?.id;
            });
            //setSelectedSimpleProperty(simple);
            setSimpleChecked(simple);
          })
          .catch((e: any) => API_SERVICE.handleErrors(e));
      }

    const getPropertyList = (name: any = null) => {
        const param = { name: name, pageSize:100 }
        API_SERVICE.getPropertyList(param)
            .then(({ data }) => {
                const displayNameListData = data.payload.content?.map((item: any) => {
                    return { key: item.id, value: item.displayName };
                });
                setObjectList(displayNameListData)
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    }

    const createObject = (payload: any) => {
        API_SERVICE.createObject(payload)
            .then(({ data }) => {
                setShowValue(false);
                setShowCollection(false)
                getObjectTemplateList();
                setAddObjectTemplate([]);
                dispatch(setAlertMsg({ message: `${payload.name} created successfully.`, description: '', showAlertMessage: true }));
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    };

    const updateObject = (payload: any) => {
        const id = editObjectTemplateData?.id
        API_SERVICE.updateObject(id, payload)
            .then(({ data }) => {
                setShowValue(false);
                setShowCollection(false)
                getObjectTemplateList();
                setAddObjectTemplate([]);
                dispatch(setAlertMsg({ message: `${payload.name} updated successfully.`, description: '', showAlertMessage: true }));
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
    }

    // const onFinish = (values: any) => {
    //     //setNextStage(true);
    //     getPropertyList();
    //         onFinalClick(values)
    // };

    const onFinish = (values: any) => {
        // Add suffix "_T" to the name field
        const valuesWithSuffix = {
            ...values,
            name: values.name.endsWith("_T") || values.name.endsWith("_")
        ? values.name.replace(/_$/, "_T") // replace _ with _T if it ends with _
        : `${values.name}_T`,
            //name: values.name.endsWith("_T") ? values.name : `${values.name}_T`,
        };
        
        getPropertyList();
        onFinalClick(valuesWithSuffix);
    };

    const onFinalClick = (values: any) => {
        //setShowValue(false)
       //setShowCollection(false)
        if (isEdit) {
            const payload = {
                "name": values?.name,
                "propertiesToAdd": addObjectTemplate,
            }
            updateObject(payload);
        } else {
            const payload = {
                "name": values?.name,
                "masterPropertyIds": addObjectTemplate,
            }
            createObject(payload);
        }
    }

    const handleListSearch = (record: any) => {
        getPropertyList(record);
    }

    const renderCheckboxList: any = (children: any, isChild: boolean = false) => {
        return (
          <>
            {children?.map((item: any, index: number) => {
              const isLast = index == children?.length - 1;
              return (
                <div
                  style={{ display: "flex", marginLeft: isChild ? "10px" : "4px" }}
                >
                  <div
                    className="line-vertical"
                    style={isLast ? { height: "11px" } : {}}
                  />
                  <div>
                    <div className="child-view">
                      <div className="line-horizontal" />
                      {item?.displayName}
                    </div>
                    {(item?.children?.length ?? 0) > 0 && (
                      <p
                        style={{
                          marginLeft: "3px",
                          marginTop: "5px",
                        }}
                      >
                        {renderCheckboxList(item?.children, true)}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </>
        );
      };
    

    const renderShowCollection = () => (
        
        <div className={"layout-main"} style={{ marginTop: "15px" }}>
            <Title level={5} style={{ padding: "17px" }}>
                {`${isEdit ? "EDIT" : "CREATE A NEW"} OBJECT TEMPLATE`}
            </Title>
            
                <div className="drawer-layout" >

                            <style>{`
                                .ant-form-item-label {
                                margin: -16px 6px 0px 0px !important;
                                
                            `}</style>
                <div className='row'>
                <div className='col-12'>
                    <div className='col-4 addcolForCreateObject' style={{float: "left"}}>  
                        <div className="drawer-form">
                            <FormInput
                                label={
                                    <>
                                        Name
                                        <Tooltip title="The Object Template name should end with ‘_T’. Ex : Address_T ">
                                            <InfoCircleOutlined style={{ marginLeft: 8, color: '#1890ff' }} />
                                        </Tooltip>
                                    </>
                                }
                                classNameForm="space-input"
                                isBorder={true}
                                name="name"
                                placeholder="Enter Name"
                                isRequired
                                requiredLabel="name"
                                rules={[
                                    {
                                        pattern:Name_REGEX,
                                        message:"Name should be single word beginning with capital letter and ending with _T"
                                    },
                                ]}
                                style={{margin: "-16px 6px 0px 0px"}}
                            />
                            
                            <Form.Item name="properties"
                            rules={[
                                {
                                validator: (_, value) => {
                                    if (!addObjectTemplate.length) {
                                    return Promise.reject(new Error('Please select at least one property'));
                                    }
                                    return Promise.resolve();
                                },
                                },
                            ]}
                            >

                                <div
                                className="preview-box "
                                style={{ marginLeft: "0px", height: "325px" }}
                                >
                                <div className="preview-name-div preview-name-div1">
                                    <label> Properties</label>
                                </div>
                                <>
                                <style>{`
                                    .searchBorder {
                                    border: 1px solid #ccc !important;
                                    border-radius: 12px;
                                    margin:5%;
                                    padding:0px;
                                    
                                `}</style>
                                <FormInput
                                placeholder="Search"
                                name="search"
                                classNameForm="space-input search-input inputval searchBorder"
                                onChange={(e: any) => {
                                masterPropertyList(e?.target?.value);
                                }}
                                isBorder={true}
                            />
                            </>
                                <div style={{ height: "200px", overflow: "auto" }}>
                                    <ul style={{ width: "100%" }}>
                                    {propertyList.map((data: any, index: number) => {
                                        return (
                                        <li style={{ listStyleType: "none", marginLeft: "-15px",paddingBottom: "6px" }}>
                                            <Panel
                                                // showArrow={
                                                // (data?.children?.length ?? 0) > 0 &&
                                                // tabChangeKey == "COMPLEX"
                                                // }
                                                // collapsible={
                                                // (data?.children?.length ?? 0) > 0 &&
                                                //     tabChangeKey == "COMPLEX"
                                                //     ? "icon"
                                                //     : "disabled"
                                                // }
                                                header={
                                                <>
                                                
                                                    <Checkbox
                                                        checked={addObjectTemplate?.some(
                                                        (id: any) => id == data?.key
                                                        ) || simpleChecked?.some(
                                                            (item: any) => item == data?.key)}
                                                        disabled={simpleChecked?.some(
                                                        (item: any) => item == data?.key)}
                                                        onChange={(e: any) => {                               
                                                        let tmp = [...addObjectTemplate];
                                                        let tmp1 = [...addObjectTemplateForValue];
                                                        
                                                        if ( 
                                                            addObjectTemplate?.includes(data?.key)
                                                        ) {
                                                            tmp = addObjectTemplate?.filter(
                                                            (item: any) => item != data?.key
                                                            );
                                                            tmp1 = addObjectTemplateForValue?.filter(
                                                                (item: any) => item != data?.value
                                                                );
                                                        } else {
                                                            tmp.push(data?.key);
                                                            tmp1.push(data?.value);
                                                        }
                                                        setAddObjectTemplate(tmp);
                                                        setAddObjectTemplateForValue(tmp1);
                                                        }}
                                                    >
                                                        {data.value}
                                                    </Checkbox>
                                                    
                                                </>
                                                }
                                                key={index}
                                            >
                                                {/* {(data?.children?.length ?? 0) > 0 &&
                                                tabChangeKey == "COMPLEX"
                                                ? renderCheckboxList(data?.children)
                                                : ""} */}
                                            </Panel>
                                        </li>
                                        );
                                    })}
                                    </ul>
                                </div>
                                </div>
                            </Form.Item>
                            
                        
                            <Space className='step-button'>
                                <FormButton
                                    size="large"
                                    label={"Cancel"}
                                    type='default'
                                    onclick={() => {setShowValue(false); setAddObjectTemplate([]);}}
                                />
                                <FormButton
                                    size="large"
                                    label={"Save"}
                                    type="primary"
                                    htmlType="submit"
                                />

                                <FormButton
                                    size="large"
                                    onclick={() => {handleShowAddProperty(true, "comeFromAddObject"); setAddObjectTemplate([]);}}
                                    //onclick={()=>{setShowAddPropery(true,"yourSpecificValue")}}
                                    label={"Add New Property"}
                                    type="primary"
                                    htmlType="button"
                                />
                            
                            </Space>
                        </div>
                    </div>
                    <div className='col-8 addColForPropetyList'> 
                        <div className='addPropetyBorder'>  
                        <h5  className='headingProperty'>Selected Property List</h5>         
                        <div className='propertydivHeight'>
                                    <ul>       
                                    {addObjectTemplateForValue.map((data: any, index: number) => {
                                        return (
                                        <li className='propertyListLI'>
                                            <span>{data}</span>
                                        </li>
                                        );
                                    })}
                                    </ul>
                        </div>
                        </div>
                </div>
                {/* <div className='col-3' style={{float: "right"}}></div> */}
               
                </div>
            </div>
            </div>
        </div>
    );
    return (
        <Form
            form={objectForm}
            layout="vertical"
            onFinish={onFinish}
        >
            {showCollection && (
                renderShowCollection()
            )}
        </Form>
    );
};

export default AddObjectTemplate;