import React, { useEffect, useState } from "react";
import { Button, Table,Popconfirm, Space } from "antd";
import editIcon from "../../../assets/images/edit_white.svg";
import deleteIcon from "../../../assets/images/delete_gray.svg";
import type { ColumnsType } from "antd/es/table";
import { IoCopy } from "react-icons/io5";
import FormButton from "../../../shared/component/FormButton/FormButton";
import NotificationMessage from "../../../shared/component/NotificationMessage/NotificationMessage";
import { API_SERVICE } from "../../../shared/services/api-services";
import { RootState } from "../../../shared/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setPropertyPageNo, setPropertyPageSize } from "../../../shared/redux/property-reducer";
import { setScreenTitle } from "../../../shared/redux/common-reducer";
import "../properties.scss";

interface DataType {
  key: React.ReactNode;
  name: string;
  object: string;
  createdBy: string;
  type: string;
  status: string;
}

interface IProperty {
  addSubId: any;
  setShowAddPropery: React.Dispatch<React.SetStateAction<boolean>>;
  sethideEnterProperty: React.Dispatch<React.SetStateAction<boolean>>;
  addSubName:any;
  setEditPropertyData: any;
}

const SubProperty: React.FC<IProperty> = (props: IProperty) => {
  const { addSubId,addSubName, setShowAddPropery,sethideEnterProperty,setEditPropertyData } = props;

  const [propertyList, setPropertyList] = useState<any[]>([]);
  const [activeRecordId, setActiveRecordId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<any[][]>([]); // Store previous records
  const property: any = useSelector((state: RootState) => state.property);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setScreenTitle(`Properties > ${addSubName} > Sub-properties `));
    getSubPropertyList(addSubId);
  }, [addSubId,addSubName]);

  const handleNameClick = (id: string,name:string) => {
    setActiveRecordId(id); // Set active record to the clicked item
    setHistory((prevHistory) => [...prevHistory, propertyList]); // Save current record to history
    dispatch(setScreenTitle(`Properties > ${addSubName} > ${name} > Sub-properties `));
    getSubPropertyList(id);
  };

  const goToEdit = (record: any) => {
    API_SERVICE.editPropertyData(record.id)
      .then(({ data }: any) => {
        setEditPropertyData(data?.payload);
        setShowAddPropery(true);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePropertyDelete = (id: any) => {
    console.log("activeRecordId",activeRecordId);
    API_SERVICE.deleteProperty(id)
      .then(() => {
        if (activeRecordId) {
          getSubPropertyList(activeRecordId); // Reload current sub-record view
        } else {
          getSubPropertyList(addSubId); // Fallback to the main list if no active record
        }
        
        //getSubPropertyList(addSubId);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
  };

  const getSubPropertyList = (id: any) => {
    setActiveRecordId(id);
    setLoading(true);
    API_SERVICE.getSubPropertyList(id)
      .then(({ data }: any) => {
        const newProperties = data?.payload?.children ?? [];
        newProperties.forEach((property: any) => {
          property.key = property.id;
        });
        setPropertyList(newProperties);
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleBackPropertyClick = () => {
    sethideEnterProperty(true);
    dispatch(setScreenTitle(`Properties > ${addSubName} > Sub-properties `)); 
  }

  const handleBackClick = () => {
    const previousRecord = history.pop(); // Get the last record from history
    console.log("handleBackClick",previousRecord);
    if (previousRecord) {
      dispatch(setScreenTitle(`Properties > ${addSubName} > Sub-properties `));
      setPropertyList(previousRecord);
      setHistory([...history]); // Update history state
      setActiveRecordId(null); // Set active ID to the restored list's first item
    } else {
      setActiveRecordId(null); // Reset if there's no history left
    }
    
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (record: any) => (
        <Button
          size="small"
          type="text"
          icon={<IoCopy />}
          onClick={() => navigator.clipboard.writeText(record)}
        />
      ),
    },
    {
      title: "API Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Name",
      dataIndex: "displayName",
      key: "displayName",
      sorter: true,
      render: (_: any, record: any) =>
        record.type === "OBJECT" ? (
          <a
            onClick={() => handleNameClick(record.id,record.name)}
            style={{ cursor: "pointer", color: "#1890ff" }}
          >
            {record.displayName}
          </a>
        ) : (
          <span>{record.displayName}</span>
        ),
    },
    {
      title: "Object Template",
      dataIndex: ["objectTemplate", "name"],
      key: "object",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: "System Property",
      dataIndex: "systemProperty",
      key: "systemProperty",
      sorter: true,
      render: (_: any, record: any) => (
        <>
          {record.systemProperty ? "Yes" : "No"}
        </>
      )
    },
    {
      title: "Vas Property",
      dataIndex: "vasProperty",
      key: "vasProperty",
      sorter: true,
      render: (_: any, record: any) => (
        <>
          {record.vasProperty ? "Yes" : "No"}
        </>
      )
    },
    {
      title: "Created By",
      dataIndex: ["createdBy", "fullName"],
      key: "createdBy",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      fixed: "right",
      render: (_: any, record: any) => (
        <>
          {record.vasProperty || record.vasProperty ?
            "" : <Space className="action-button" size="small">
              <Button
                style={{ borderRadius: "6px", background: "#1A3175" }}
                size="small"
                type="primary"
                icon={<img src={editIcon} alt="" />}
                onClick={() => goToEdit(record)}
              >
                Edit
              </Button>
              <Popconfirm
                placement="topRight"
                title="Are you sure to delete?"
                okText="Yes"
                cancelText="No"
                className="remove"
                onConfirm={() => handlePropertyDelete(record?.id)}
              >
                <Button
                  className="deleteButton"
                  size="small"
                  icon={<img src={deleteIcon} alt="" />}
                >
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          }</>
      ),
    },
  ];

  const handlePaginationChange = (current: any, pageSize: any) => {
    dispatch(setPropertyPageNo(current));
    dispatch(setPropertyPageSize(pageSize));
    getSubPropertyList(addSubId);
  };

  return (
    <>
      <NotificationMessage />
      
              { history.length > 0 ? (
                <Button type="link" onClick={handleBackClick} className="backToProperty">
                Back To Property
              </Button>
              
              ): (
                <Button type="link" onClick={handleBackPropertyClick} className="backToProperty">
                Back To Property
              </Button>
                
              )}
      <div style={{ marginTop: "20px" }}>
        <Table
          columns={columns}
          dataSource={propertyList}
          loading={loading}
          onChange={(pagination: any) => {
            handlePaginationChange(pagination.current, pagination.pageSize);
          }}
          pagination={{
            position: ["bottomCenter"],
            current: property.pageNo,
            pageSize: property.pageSize,
            total: propertyList.length,
          }}
          expandable={{
            showExpandColumn: false,
          }}
          scroll={{ x: "max-content" }}
        />
      </div>
    </>
  );
};

export default SubProperty;