import React, { useEffect, useState } from "react";
import { Form, Row, Space, Typography,Input,notification,Card,List } from 'antd';
import FormInput from '../../../../shared/component/FormInput/FormInput';
import FormButton from '../../../../shared/component/FormButton/FormButton';
import FormSelect from '../../../../shared/component/FormSelect/FormSelect';
import FormTextArea from '../../../../shared/component/FormTextArea/FormTextArea';
import { API_SERVICE } from '../../../../shared/services/api-services';
import { DownOutlined } from "@ant-design/icons";


import FormIconButton from "../../../../shared/component/FormIconButton/FormIconButton";
import "../../properties.scss"
const { Text } = Typography;

interface IAddBasicInfo {
    setSelectedStage: Function;
    setFormData1: any;
    formData1: any;
    setAddPropery: any;
    selectedStage: number;
    setObjectList: any;
    objectList: any;
    specificValue:any;
    isEdit: boolean;
    setEditPropertyData:any;
}
const AddBasicInfo: React.FC<IAddBasicInfo> = (props: IAddBasicInfo) => {
    const { selectedStage,specificValue,  setSelectedStage, setFormData1, formData1, setAddPropery, setObjectList, objectList, isEdit,setEditPropertyData } = props

    const [propertyTypeValue, setPropertyTypeValue] = useState(formData1?.propertyType);
    const [propertiesListFilter, setPropertiesListFilter] = useState([] as any);
    const [fieldTypeValue, setFieldTypeValue] = useState(formData1?.fieldType);
    const [fieldTypeForm] = Form.useForm() as any;

    const defaultPropertys = formData1?.children?.map((obj: any) => obj?.id ?? obj?.key)

    const [basicInfoForm] = Form.useForm() as any;
    const [propertiesIndex, setPropertiesIndex] = useState(Number);
    const [isPropertyListModal, setIsPropertyListModal] = useState(false);

    //const defaultPropertyList = formData1?.propertyList || [];

    

    useEffect(() => {
        if (propertyTypeValue === "object") {
            const defaultSelectedProperty = formData1?.propertyList; 
             // Adjust if there's a specific property to load by default
            if (defaultSelectedProperty) {
                getPropertiesList(defaultSelectedProperty);
            }
        }
    }, [propertyTypeValue]);

    useEffect(() => {

        if(isEdit)
        {
            if (propertyTypeValue === "object") {

                getObjectList();
            }
        }
        basicInfoForm.setFieldsValue({
            labelInput: formData1?.labelInput,
            description: formData1?.description,
            propertyType: formData1?.propertyType,
            fieldType: formData1?.fieldType?.toLowerCase(),
            propertyList: formData1?.propertyList,
        });
    }, [])

    const onNextClick = (record: any) => {
        setSelectedStage(2)
        const filterProperty = [...objectList, ...getPropertyOption()]?.filter((item:any) => record?.propertyList?.includes(item?.key))
        const submitData = {
            labelInput: record?.labelInput,
            description: record?.description,
            propertyType: record?.propertyType,
            fieldType: record?.fieldType,
            children: filterProperty,
            propertyList: record?.propertyList
        }
        setFormData1(submitData)
    }

    // const onNextClick = (record: any) => {
    //     setSelectedStage(3);
    //     const filterProperty = [...objectList, ...getPropertyOption()]?.filter((item:any) => record?.propertyList?.includes(item?.key))
    //     const submitData = {
    //         ...formData2,
    //         propertyType: record?.propertyType,
    //         fieldType: record?.fieldType,
    //         children: filterProperty,
    //         propertyList: record?.propertyList
    //     }
    //     setFormData2(submitData)
    // }

    const getPropertyOption = () => {
        const propertyOption = formData1?.children?.map((obj: any) => {
            return {
                key: obj?.id ?? obj?.key,
                value: obj?.name ?? obj?.value
            }
        });
        return propertyOption ?? []
    }

    const getObjectList = (
        pageNo: number = 1,
        pageSize: any = 10,
        // parentId: any = null,
        // direction: any = null,
        name: any = null,
      ) => {
        const params = {
          pageNo: pageNo,
          pageSize: pageSize,
          name: name,
        //   direction: direction,
        };
        API_SERVICE.getObjectList(params)
          .then(({ data }: any) => {
            const displayNameListData = data?.payload?.content?.map((item: any) => {
                return { key: item.id, value: item.name };
            });
            const arr = displayNameListData?.filter((item: any) => !defaultPropertys?.includes(item?.key));
            setObjectList(arr)
          })
          .catch((e: any) => {
            API_SERVICE.handleErrors(e);
          })
          .finally(() => {
           // setLoading(false);
          });
      };

      const renderList = (
        item: any,
        index: number,
        children: any,
        isChild = false
      ) => {
        return (
          <>
          
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                
              <div className="child-view">
                {isChild && <div className="line-horizontal" />}
                <span>{item.displayName}</span>
              </div>
              {children?.length > 0 ? (
                ""
              ) : (
                <div
                  onClick={() => {
                   // if (isPropertyListModal) {
                      let fieldsObj: any;
                      const setFields: any = [];
                      basicInfoForm
                        .getFieldsValue()
                        ?.fields?.map((fieldData: any) => {
                          if (
                            fieldData?.piHolder ==
                            "$" + `${propertiesIndex + 1}`
                          ) {
                            fieldsObj = {
                              property: item.path.includes(".")
                                ? item?.path?.replaceAll(".", "!.")
                                : item?.path
                            };
                          } else {
                            fieldsObj = fieldData;
                          }
                          setFields.push(fieldsObj);
                        });
                        basicInfoForm.setFieldsValue({
                        fields: setFields,
                      });
                  }}
                />
              )}
            </div>
    
            {item?.children?.length > 0 &&
              children?.map((data: any, index: number) => {
                const isLast = index == children?.length - 1;
    
                return (
                  <div
                    style={{
                      marginLeft: isChild ? "20px" : "10px",
                      // borderLeft: "1px solid gray",
                      display: "flex",
                    }}
                  >
                    <div
                      className="line-vertical"
                      style={isLast ? { height: "22px" } : {}}
                    />
                    <p
                      style={{
                        // marginLeft: "10px",
                        marginTop: "5px",
                        marginBottom: "0px",
                        width: "100%",
                      }}
                    >
                      {renderList(data, index, data?.children, true)}
                    </p>
                  </div>
                );
              })}
          </>
        );
      };

    const propertyType: any = [
        {
            key: "simple",
            value: "Simple"
        },
        {
            key: "object",
            value: "Object"
        }
    ];

    const fieldType: any = [
        {
            key: "TEXT",
            value: "Text"
        },
        {
            key: "NUMBER",
            value: "Number"
        },
        {
            key: "DATE",
            value: "Date"
        },
        {
            key: "DOUBLE",
            value: "Double"
        },
        {
            key: "BOOLEAN",
            value: "Boolean"
        }
    ]

    const onPropertySearch = (value: any = null) => {
        getObjectList(1,10,value);
    }

    const onPropertyTypeChange = (value: any) => {
        setPropertyTypeValue(value);
        if (value === "object") {
            getObjectList();
        }
    }

    const onFieldTypeChange = (value: any) => {
        setFieldTypeValue(value)
    }

    const order = {
        items: [{}],
      };

    const itemInputs = order.items.map((item: any) => {
        return {
          property: item.property,
        };
      });

      const getPropertiesList = (selectedProperty: any) => {
        if(selectedProperty!=undefined){
            API_SERVICE.getObjectProperty(selectedProperty)
              .then(({ data }) => {
                const displayNameListData = Array.isArray(data.payload?.properties)
              ? data.payload.properties.map((item: any) => ({
                  path: item.path,
                  displayName: item.displayName,
                  children: item.children,
                }))
              : []; // Fallback to an empty array if properties is not iterable

            // Update the state with the processed data
            setPropertiesListFilter(displayNameListData);
          })
              .catch((e: any) => API_SERVICE.handleErrors(e));
        }else{
          setPropertiesListFilter("");
        }
    };

    

    return (
        <Form
            form={basicInfoForm}
            layout="vertical"
            onFinish={onNextClick}>
        
        <div className="row">
        <div className="col-4">
            <FormInput
                label="Label"
                classNameForm="space-input"
                isBorder={true}
                name="labelInput"
                placeholder="Enter Label"
                requiredLabel="label"
                isRequired
            />
            <FormTextArea
                label="Description"
                classNameForm="space-input"
                isBorder={true}
                name="description"
                placeholder="Enter Description"
            />

            <FormSelect
                label="Property Type"
                name="propertyType"
                placeholder="Select Property Type"
                classNameForm="space-input"
                isBorder
                options={propertyType}
                onChange={onPropertyTypeChange}
                disabled={isEdit}
                isRequired
            />
            {(propertyTypeValue === "simple") ?
                <>
                    <FormSelect
                        label="Field Type"
                        name="fieldType"
                        classNameForm="space-input"
                        placeholder="Enter Field Type"
                        isBorder
                        disabled={isEdit}
                        options={fieldType}
                        onChange={onFieldTypeChange}
                        isRequired
                    />
                    <div className='preview-box'>
                        <div className="preview-name-div">
                            <label >Preview</label>
                        </div>

                        <div className='preview-marging'>
                            <Text className='text-color'>
                                {fieldTypeValue?.toLowerCase() === "text" && "Name"}
                                {fieldTypeValue?.toLowerCase() === "number" && "0000"}
                                {fieldTypeValue?.toLowerCase() === "boolean" && "True/False"}
                                {fieldTypeValue?.toLowerCase() === "date" && "DD/MM/YYYY"}
                                {fieldTypeValue?.toLowerCase() === "double" && "0.00"}
                            </Text>
                        </div>
                        <label className='preview-lable' >{fieldTypeValue}</label>
                    </div>

                </> : (propertyTypeValue === "object") ?
                    <>
                        <FormSelect
                        label="Object Template List"
                            name="propertyList"
                            optionFilterProp="children"
                            placeholder="Object Template List"
                            isBorder
                            disabled={isEdit}
                            maxTagCount="responsive"
                            onChange={(selectedValues) => getPropertiesList(selectedValues)}
                            onFocus={()=>{onPropertySearch()}}
                            onSearch={onPropertySearch}
                            options={[...objectList, ...getPropertyOption()]}
                            isRequired
                        />
                    </> : ""
            }</div>
            <div className="col-2"></div>
                            <div className="col-6">
                            {(propertyTypeValue === "object") ?
                            <>
                            
                            <p>Properties List</p>
                            <Card style={{ width: "100%", height: 370, overflow: "auto" }}>
          
                            <List
                                grid={{ gutter: 16, column: 1 }}
                                dataSource={propertiesListFilter}
                                itemLayout="horizontal"
                                renderItem={(item: any, index: number) => (
                                <List.Item style={{ marginBottom: "4px" }}>
                                    {renderList(item, index, item?.children)}
                                </List.Item>
                                )}
                            />
                            </Card>
                            </> : ""
                            }
        
                            </div>

        
            
            <Space className='step-button' style={{marginTop: "20px"}}>
                <FormButton
                    label="Cancel"
                    onclick={() => {
                        setAddPropery(false);
                        props.setEditPropertyData?.(null);
                    }}
                />
                <FormButton
                    size="large"
                    label="Next"
                    type="primary"
                    htmlType="submit"
                />
            </Space>

        </div>

            
        </Form>
    );
};

export default AddBasicInfo;