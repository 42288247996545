import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Form, Input, Modal, notification, Pagination, Popconfirm, Space, Typography } from 'antd';
import FormIconButton from '../../../shared/component/FormIconButton/FormIconButton';
import DecisioningRule from './DecisioningRule';
import FormButton from '../../../shared/component/FormButton/FormButton';
import searchIcon from "../../../assets/images/search.svg";
import { API_SERVICE } from '../../../shared/services/api-services';
import OfferTestOutput from '../OfferCalculation/OfferTestOutput';
import { setScoreCardPageNo, setScoreCardPageSize, setScoreCardSearchParam } from '../../../shared/redux/scoreCard-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../shared/redux/store';

interface IDecisioningDraft {
  currentPayloadData: any;
  setCurrentPayloadData: any;
  decisionData: any;
  setOpenPublishComment: any;
  setTestDecision: any;
  setIsAddRuleClicked: any;
  isAddRuleClicked: any;
  isCreateTable: any;
  setIsCreateTable: any;
  setIsAddRule: any;
  isAddRule: any;
  getDraftList: any;
  setShowAddAction: any;
  setIsEditAction: any;
  setEditActionData: any;
  setEditActionIndex: any;
  setShowAddCondition: any;
  setIsEditCondition: any;
  setEditConditionData: any;
  setIsShowTest: any;
  isShowTest: any;
  testRule: any;
  testTotalScore: any;
  testedStatus: any;
  setIsShowCancelBtn: any;
  isShowCancelBtn: any;
  isDataTested: boolean;
  eligibleLendersArr: any;
  offerCalculationLenderOutputResponses: any;
  conditionEvaluationResults: any;
}
const { Text } = Typography;
const DecisioningDraft: React.FC<IDecisioningDraft> = (props: IDecisioningDraft) => {
  const {
    currentPayloadData,
    setCurrentPayloadData,
    setIsAddRuleClicked,
    isAddRuleClicked,
    isCreateTable,
    setIsCreateTable,
    setIsAddRule,
    isAddRule,
    getDraftList,
    decisionData,
    setTestDecision,
    setShowAddAction,
    setIsEditAction,
    setEditActionData,
    setEditActionIndex,
    setIsEditCondition,
    setShowAddCondition,
    setEditConditionData,
    setOpenPublishComment,
    setIsShowTest,
    isShowTest,
    testRule,
    testTotalScore,
    testedStatus,
    setIsShowCancelBtn,
    isShowCancelBtn,
    isDataTested,
    eligibleLendersArr,
    offerCalculationLenderOutputResponses,
    conditionEvaluationResults,
  } = props;
  const [scorecardData, setScoreCardData] = useState(currentPayloadData?.ruleMetadata as any);
  const [scorecardDataFilter, setScoreCardDataFilter] = useState([] as any);
  const [isCancel, setIsCancel] = useState(false);
  const [isCancelPop, setIsCancelPop] = useState(false);
  const [isLoanOffer, setIsLoanOffer] = useState(false);
  const [loanOfferData, setLoanOfferData] = useState({} as any);
  const [checkIsEdit, setCheckIsEdit] = useState(false);
  const [conditionWidth, setConditionWidth] = useState("");
  const [columnsHeight, setColumnsHeight] = useState("");
  const [actionWidth, setActionWidth] = useState("");
  const [scoreCardSearchForm] = Form.useForm();
  const dispatch = useDispatch();
  const scoreCard: any = useSelector((state: RootState) => state.scoreCard);
  const conditionRef: any = useRef(null);
  const actionRef: any = useRef(null);
  const columnRef: any = useRef(null);

  useEffect(() => {
    setColumnsHeight(columnRef.current ? columnRef.current.clientHeight : 0);
    setConditionWidth(conditionRef.current ? conditionRef.current.offsetWidth : 0);
    setActionWidth(actionRef.current ? actionRef.current.offsetWidth : 0);
  }, [conditionRef.current || actionRef.current || columnRef.current]);

  useEffect(() => {
    if (isCreateTable) {
      dataFilterSearch(currentPayloadData?.ruleMetadata)
      setScoreCardData(currentPayloadData?.ruleMetadata)
      setIsCreateTable(false);
    }
  }, [isCreateTable])

  const onAddRule = (newData: any) => {
    const isRuleName = newData?.some((item: any) => item?.ruleName?.length == 0);
    if (isRuleName) {
      notification.error({ message: "Please enter all rule name" });
      return;
    }
    const payload = {
      currentPayload: {
        ...currentPayloadData,
        ruleMetadata: newData,
      },
      decisionType: decisionData?.decisionType,
      description: decisionData?.description,
      masterProductId: decisionData?.masterProductId,
      dsaBusinessId: decisionData?.id,
      name: decisionData?.name,
      purpose: decisionData?.purpose,
    };
    API_SERVICE.updateDecisioning(decisionData?.id, payload)
      .then(() => {
        setIsCancel(true);
        setIsShowCancelBtn(false);
        getDraftList();
      })
      .catch((e: any) => {
        API_SERVICE.handleErrors(e);
      });
    setIsAddRule(false)
  };

  const dataFilterSearch = (data?: any) => {
    const tmpData = data ?? scorecardData;
    const searchText = scoreCardSearchForm?.getFieldValue("searchRule");
    if ((searchText?.length ?? 0) == 0) {
      setScoreCardDataFilter([]);
      return;
    }
    if (decisionData?.decisionType == "OFFER_DECISION") {
      let tmpScoreMap = tmpData?.map((item: any) => {
        const rules = item?.ruleName?.split("-");
        const getLenderName = eligibleLendersArr?.find(
          (lend: any) => lend?.key == rules?.at(0)
        );
        item["lenderName"] = getLenderName?.value;
        return item;
      });
      const filterSearch = tmpScoreMap?.filter((item: any) => {
        return (
          item?.ruleName == "" ||
          item?.lenderName?.toLowerCase().includes(searchText?.toLowerCase())
        );
      });
      setScoreCardDataFilter(filterSearch);
    } else {
      const filterSearch = tmpData?.filter((item: any) => {
        return (
          item?.ruleName == "" ||
          item?.ruleName?.toLowerCase().includes(searchText?.toLowerCase())
        );
      });
      setScoreCardDataFilter(filterSearch);
    }
  };

  const handlePagination = (pageNo: any, pageSize: any) => {
    dataFilterSearch();
    setIsCancel(true)
    dispatch(setScoreCardPageNo(pageNo));
    dispatch(setScoreCardPageSize(pageSize));
  };

  const renderCancelConfirm = () => {
    return (
      <Modal
        width={300}
        open={isCancelPop}
        closable={false}
        footer={null}
        centered
      >
        <p style={{ textAlign: "center", fontSize: "20px" }}>
          Are you sure to cancel data?
        </p>
        <Space style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="large"
            style={{ width: "120px" }}
            onClick={() => setIsCancelPop(false)}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            style={{ width: "120px" }}
            onClick={() => {
              getDraftList();
              setIsCancel(true);
              setIsCancelPop(false);
              setIsShowCancelBtn(false)
            }}
          >
            Yes
          </Button>
        </Space>
      </Modal>
    );
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginRight: "10px" }}>
        <Form
          layout="inline"
          form={scoreCardSearchForm}
          style={{ marginBottom: "20px", marginLeft: "20px" }}
          onFinish={(values: any) => {
            if(checkIsEdit){
              setIsCancel(true);
            }
            dataFilterSearch();
            dispatch(setScoreCardPageNo(1));
          }}
        >
          <Form.Item name="searchRule">
            <Input
              size="large"
              className="search-input"
              prefix={<img src={searchIcon} alt="" />}
              placeholder="Search"
              allowClear
            />
          </Form.Item>
          {/* <FormInput classNameForm="mt-0" name="search" isBorder /> */}
          <FormButton label="Search" htmlType="submit" />
        </Form>
        <Popconfirm
          placement="topRight"
          title={<div style={{ width: "250px" }}>{"All rules will get over-written on using paste, please confirm to continue?"}</div>}
          onConfirm={async () => {
            const errorMsg = "Please click on Copy icon for chosen Rule to enable Paste Decisioning action"
            try {
              const data = await navigator.clipboard.readText();
              const payload = JSON.parse(data);
              if (payload?.conditionMetadata?.length > 0) {
                setCurrentPayloadData(payload);
                setIsCreateTable(true);
                setIsShowCancelBtn(true);
                setIsShowTest(false);
              } else {
                API_SERVICE.handleErrors(errorMsg);
              }
            } catch (e: any) {
              API_SERVICE.handleErrors(errorMsg);
            }
          }}
        >
          <Button size="large" type="primary">
            Paste Rules
          </Button>
        </Popconfirm>
      </div>
      <div style={{ width: "100%", overflow: "auto" }}>
        <Space className='list' ref={columnRef} style={{ alignItems: "baseline", minWidth: "100%", "--height": `${columnsHeight}px` ?? "" } as any}>
          <p style={{ width: (decisionData?.decisionType == "OFFER_DECISION" && checkIsEdit) ? "360px" : "175px", fontWeight: "600", marginLeft: "10px" }}>Rule Name</p>
          {currentPayloadData?.conditionMetadata?.map((item: any) => (
            <Space ref={conditionRef} className='con-list' direction='vertical' style={{ minWidth: "310px", width: "100%", fontWeight: "600", textOverflow: "ellipsis", wordBreak: "break-all" }}>
              <div style={{ display: 'flex', justifyContent: "space-between" }}>
                <span>Condition</span>
                {decisionData?.decisionType != "OFFER_DECISION" && (
                  <FormIconButton
                    type={"editBlack"}
                    className="columns-edit"
                    onClick={() => {
                      setIsShowTest(false);
                      setEditConditionData(item ?? {});
                      setIsEditCondition(true);
                      setShowAddCondition(true);
                    }}
                  />
                )}
              </div>
              <Divider style={{ margin: 0 }} />
              <p style={{ marginBottom: 0 }}>{item?.conditionName}</p>
              <Divider style={{ margin: 0 }} />
              <p style={{ marginBottom: 10 }}>{item?.expression}</p>
            </Space>
          ))}
          {currentPayloadData?.actionMetadata?.map((item: any, index: number) => {
            return (
              <Space ref={actionRef} className='act-list' direction='vertical' style={{ minWidth: "310px", width: "100%", fontWeight: "600", textOverflow: "ellipsis", wordBreak: "break-all" }}>
                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                  <span>Action</span>
                  {decisionData?.decisionType != "OFFER_DECISION" && (
                    <FormIconButton
                      type={"editBlack"}
                      className="columns-edit"
                      onClick={() => {
                        setIsShowTest(false);
                        setEditActionIndex(index);
                        setEditActionData(item ?? {});
                        setIsEditAction(true);
                        setShowAddAction(true);
                      }}
                    />
                  )}
                </div>
                <Divider style={{ margin: 0 }} />
                <p style={{ marginBottom: 0 }}>{item?.actionName}</p>
                <Divider style={{ margin: 0 }} />
                <p style={{ marginBottom: 10 }}>{item?.description}</p>
              </Space>
            )
          })}
          <p style={{ minWidth: "150px", maxWidth: "200px", color: testTotalScore > 0 ? "black" : "transparent" }}>
            {testTotalScore > 0 ? (
              <div style={{ textAlign: "center", display: "grid" }}>
                Total Score <br />
                <span style={{ fontSize: "18px" }}>{testTotalScore}</span>
                <span>Status: {testedStatus}</span>
              </div>
            ) : (
              "test"
            )}
          </p>
        </Space>
        {scorecardDataFilter?.length > 0 ? (
          <>
            {scorecardDataFilter?.slice(scoreCard?.pageNo == 1 ? 0 : (scoreCard?.pageNo - 1) * scoreCard?.pageSize, scoreCard?.pageNo * scoreCard?.pageSize)?.map((item: any, index: number) => {
              const findIndex = scorecardData?.findIndex((item1:any)=> item1?.ruleMetaDataId == item?.ruleMetaDataId);
              return (
                <>
                  <DecisioningRule
                    data={item}
                    conditionEvaluationResults={conditionEvaluationResults}
                    conditionMetadata={currentPayloadData?.conditionMetadata}
                    eligibleLenders={eligibleLendersArr}
                    actionMetadata={currentPayloadData?.actionMetadata}
                    decisionData={decisionData}
                    isCancel={isCancel}
                    setIsCancel={setIsCancel}
                    setScoreCardData={setScoreCardData}
                    scorecardData={scorecardData}
                    ruleIndex={isAddRuleClicked ? 0 : findIndex}
                    setIsShowCancelBtn={setIsShowCancelBtn}
                    onAddRule={onAddRule}
                    isAddRuleClicked={isAddRuleClicked}
                    setIsAddRuleClicked={setIsAddRuleClicked}
                    setIsAddRule={setIsAddRule}
                    isShowTest={isShowTest}
                    setIsShowTest={setIsShowTest}
                    offerCalculationLenderOutputResponses={offerCalculationLenderOutputResponses}
                    setIsLoanOffer={setIsLoanOffer}
                    setLoanOfferData={setLoanOfferData}
                    testRule={testRule}
                    checkIsEdit={checkIsEdit}
                    setCheckIsEdit={setCheckIsEdit}
                    actionWidth={actionWidth}
                    conditionWidth={conditionWidth}
                    dataFilterSearch={dataFilterSearch}
                  />
                </>
              )
            })}
          </>
        ) : (
          <>
            {scorecardData?.slice(scoreCard?.pageNo == 1 ? 0 : (scoreCard?.pageNo - 1) * scoreCard?.pageSize, scoreCard?.pageNo * scoreCard?.pageSize)?.map((item: any, index: number) => {
              const findIndex = scorecardData?.findIndex((item1:any)=> item1?.ruleMetaDataId == item?.ruleMetaDataId);
              return (
                <>
                  <DecisioningRule
                    data={item}
                    conditionEvaluationResults={conditionEvaluationResults}
                    conditionMetadata={currentPayloadData?.conditionMetadata}
                    eligibleLenders={eligibleLendersArr}
                    actionMetadata={currentPayloadData?.actionMetadata}
                    decisionData={decisionData}
                    isCancel={isCancel}
                    setIsCancel={setIsCancel}
                    setScoreCardData={setScoreCardData}
                    scorecardData={scorecardData}
                    ruleIndex={findIndex}
                    setIsShowCancelBtn={setIsShowCancelBtn}
                    onAddRule={onAddRule}
                    isAddRuleClicked={isAddRuleClicked}
                    setIsAddRuleClicked={setIsAddRuleClicked}
                    setIsAddRule={setIsAddRule}
                    isShowTest={isShowTest}
                    setIsShowTest={setIsShowTest}
                    offerCalculationLenderOutputResponses={offerCalculationLenderOutputResponses}
                    setIsLoanOffer={setIsLoanOffer}
                    setLoanOfferData={setLoanOfferData}
                    testRule={testRule}
                    checkIsEdit={checkIsEdit}
                    setCheckIsEdit={setCheckIsEdit}
                    actionWidth={actionWidth}
                    conditionWidth={conditionWidth}
                    dataFilterSearch={dataFilterSearch}
                  />
                </>
              )
            })}
          </>
        )}
        <Divider style={{ margin: 0 }} />
      </div>
      <div style={{ display: "flex", justifyContent: (scorecardData?.length ?? 0) !== 0 ? "space-between" : "end" }}>
        {(scorecardData?.length ?? 0) !== 0 && (
          <Pagination
            defaultCurrent={scoreCard?.pageNo}
            current={scoreCard?.pageNo}
            defaultPageSize={scoreCard?.pageSize}
            pageSize={scoreCard?.pageSize}
            //default size of page
            onChange={handlePagination}
            size='small'
            style={{ alignContent: "center", marginTop: "-12px" }}
            showSizeChanger
            total={scorecardDataFilter?.length > 0 ? scorecardDataFilter?.length : scorecardData?.length} //total number of card data available
          />
        )}
        <Space style={{ gap: "12px", marginRight: "15px", marginTop: "13px" }}>
          <FormButton
            label='Save'
            type='primary'
            style={{ background: "#20A74E" }}
            onclick={() => onAddRule(scorecardData)}
          />
          {isShowCancelBtn ? (
            <>
              <FormButton label='Cancel' onclick={() => {
                setIsCancelPop(true)
              }} />
              {isCancelPop && renderCancelConfirm()}
            </>
          ) : (
            <>
              <FormButton
                label="Test"
                style={{ background: "#C71C8E" }}
                type="primary"
                onclick={() => {
                  if (isShowCancelBtn || isAddRule) {
                    notification.error({ message: "Please Save rule" });
                    return;
                  }
                  API_SERVICE.decisionVaildate(decisionData?.id)
                    .then((data) => {
                      setTestDecision(
                        currentPayloadData?.ruleMetadata?.length > 0
                      );
                    })
                    .catch((e: any) => API_SERVICE.handleErrors(e));
                }}
              />
              <FormButton
                label="Publish"
                type="primary"
                disabled={!isDataTested}
                onclick={() => {
                  setOpenPublishComment(
                    currentPayloadData?.ruleMetadata?.length > 0
                  );
                }}
              />
            </>
          )}
        </Space>
      </div>
      <Modal
        title="Loan Offer"
        open={isLoanOffer}
        onCancel={() => setIsLoanOffer(false)}
        footer={false}
      >
        <OfferTestOutput offerTestedData={loanOfferData} />
      </Modal>
    </>
  );
};

export default DecisioningDraft;