import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import "./select.scss"

interface IOption {
    value: string;
    label: string;
}

interface ISelect {
    defaultValue?: string;
    disabled?: boolean;
    onChange?:
    | ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void)
    | undefined;
    onSearch?: any
    placeholder?: string;
    value?: string;
    mode?: "multiple" | "tags";
    onFocus?: undefined | any;
    optionFilterProp?: string;
    maxTagCount?: "responsive";
    style?: any;
    options?: IOption[] | undefined;
}

const SelectList: React.FC<ISelect> = (props: ISelect) => {
    const {
        defaultValue,
        disabled,
        onChange,
        onSearch,
        placeholder,
        value,
        mode,
        onFocus,
        optionFilterProp,
        maxTagCount,
        style,
        options
    } = props;
    return (
        <Select
            size="large"
            defaultValue={defaultValue}
            className="select"
            showSearch
            allowClear
            disabled={disabled}
            onChange={onChange}
            onSearch={onSearch}
            placeholder={placeholder}
            value={value}
            mode={mode}
            onFocus={onFocus}
            optionFilterProp={optionFilterProp}
            maxTagCount={maxTagCount}
            style={style}
        >
            {options?.
                sort(function (a: any, b: any) {
                    if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
                    if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
                    return 0;
                })
                ?.map((option: any, i: number) => (
                    <Select.Option key={i} value={option.key}>
                        {option.value}
                    </Select.Option>
                ))}
        </Select>
    )
};

export default SelectList;