import {
    Button,
    Col,
    Collapse,
    Popconfirm,
    Row,
    Tooltip,
    notification,
} from "antd";
import React, { useEffect, useState } from "react";
// import { ReactComponent as IconUpload } from "../../../../../assets/images/upload-border.svg";
// import { ReactComponent as IconDownload } from "../../../../../assets/images/download-border.svg";
// import { ReactComponent as IconAdd } from "../../../../../assets/images/add-border.svg";
// import { ReactComponent as IconDelete } from "../../../../../assets/images/delete.svg";
// import { ReactComponent as IconDeleteDisable } from "../../../../../assets/images/delete_disable.svg";
//   import BankStatementV2 from "./BankStatementV2";
// import AppUpload from "../../AppUpload/app-upload";
import './Document.scss';
import { useSelector } from "react-redux";
import FormIconButton from "../../../../shared/component/FormIconButton/FormIconButton";
import ViewDocument from "../../../../shared/component/ViewDocument/ViewDocument";

const { Panel } = Collapse;

interface IDocCategoryV2 {
    documentCategoryList: any;
    getDocumentList: any;
    bankList: any;
}

const DocCategoryV2: React.FunctionComponent<IDocCategoryV2> = (
    props: IDocCategoryV2
) => {

    const {
        documentCategoryList,
        getDocumentList,
        bankList,
    } =
        props;

    // const [uploadDocumentList, setUploadDocumentList] = useState({ sucess: [], failed: [] } as any);
    const [showViewDocument, setShowViewDocument] = useState(false);
    const [businessDocumentId, setBusinessDocumentId] = useState("");

    const renderItem = (doc: any, index: any) => {
        const docType = doc?.documentOrgType ?? doc?.documentType;
        const docName = doc?.documentTypeName ?? docType?.split("~").at(-1);
        const fileName = doc?.documentDetails?.documentName;

        return (
            <div className="document-upload">
                <Row
                    key={`${doc?.documentType}_${index}`}
                    className={
                        doc?.documentDetails &&
                            doc?.maximumSizeInMB <= doc?.documentDetails?.fileSize
                            ? "error"
                            : ""
                    }
                >
                    <Col
                        span={7}
                        style={{
                            textOverflow: "ellipsis",
                            flexWrap: "nowrap",
                            overflow: "hidden",
                            paddingLeft: "15px",
                        }}
                    >
                        <Tooltip title={docName}>
                            <span style={{ color: "red" }}>
                                {doc?.mandatory ? "* " : <>&nbsp;&nbsp;&nbsp;</>}
                            </span>
                            <span>{docName}</span>
                        </Tooltip>
                    </Col>
                    <Col
                        span={7}
                        style={{
                            textOverflow: "ellipsis",
                            flexWrap: "nowrap",
                            overflow: "hidden",
                        }}
                    >
                        <div className="action-btns">
                            <span style={{ lineBreak: "anywhere" }}>{fileName}</span>
                        </div>
                    </Col>
                    <Col span={4} className="h-center">
                        <Tooltip
                            title={
                                doc?.appliedFileTypes ? doc?.appliedFileTypes?.join("/") : "-"
                            }
                        >
                            <p
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    marginBottom: "0px",
                                    paddingInline: "10px",
                                }}
                            >
                                {doc?.appliedFileTypes
                                    ? doc?.appliedFileTypes?.join(", ")
                                    : "-"}
                            </p>
                        </Tooltip>
                    </Col>
                    <Col span={3} className="h-center">
                        {doc?.maximumSizeInMB ? doc?.maximumSizeInMB + "MB" : "-"}
                    </Col>
                    <Col span={3}>
                        <FormIconButton
                            type="eye"
                            onClick={() => {
                                if (doc?.documentDetails?.businessDocumentId) {
                                    setBusinessDocumentId(doc?.documentDetails?.businessDocumentId)
                                    setShowViewDocument(true);
                                }
                            }}
                        />
                    </Col>
                </Row>
                {/* {doc?.documentTypeType.includes("BANK_STATEMENT") && doc?.documentDetails && (
            <BankStatementV2 doc={doc} index={index} bankList={bankList} />
          )} */}
            </div>
        );
    };

    const getHeader = () => {
        let isRequired = false;
        let mandatoryCount = 0;
        documentCategoryList?.documentTypeWithUploadedDetails?.map((item: any) => {
            if (!isRequired && item?.mandatory) {
                isRequired = true;
            }
            if (item?.mandatory && item.documentDetails !== null) {
                mandatoryCount = mandatoryCount + 1;
            }
        });

        const reqSign = (
            <span style={{ color: "red" }}>{isRequired ? "* " : "  "}</span>
        );

        return (
            <>
                <div className="doc-mobile-items-header">
                    <div>
                        {reqSign}
                        {`${documentCategoryList?.documentCategoryName} `}
                    </div>
                    <div>
                        {
                            <span className="doc-category-tags" >
                                Mandatory: {mandatoryCount ?? 0}/
                                {documentCategoryList?.totalMandatory}
                            </span>
                        }
                        {
                            <span className="doc-category-tags" >
                                Uploaded: {documentCategoryList?.totalUploaded}
                            </span>
                        }
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <Collapse
                className="doc-collapse"
                expandIconPosition="end"
                key={`${documentCategoryList?.documentCategoryId}`}
            >
                <Panel header={getHeader()} key={"1"}>
                    {documentCategoryList?.documentTypeWithUploadedDetails?.map(
                        (item: any, index: number) => {
                            return renderItem(item, index);
                        }
                    )}
                </Panel>
            </Collapse>
            {showViewDocument &&
                <ViewDocument
                    showPopDocument={showViewDocument}
                    setIsShowPopDocument={setShowViewDocument}
                    businessDocumentId={businessDocumentId} />
            }
        </>
    );
};

export default DocCategoryV2;
