import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";

// import iconSearch from "../../../../../assets/images/search.png";
import { useDispatch, useSelector } from "react-redux";

// import imgDocument from "../../../../../assets/images/document_download.svg";
// import imgTickRight from "../../../../../assets/images/tickRight.svg";
// import imgLoading from "../../../../../assets/images/loading.svg";
// import wrong from "../../../../../assets/images/wrong.svg";
import './Document.scss';
import { API_SERVICE } from "../../../../shared/services/api-services";
import DocCategoryV2 from "./DocCategoryV2";

interface IDocument {
  setIsLoading: any;
  leadData: any;
  getTaskData: any;
  // isCurrentDocument:any;
}

const Document: React.FunctionComponent<IDocument> = (props: IDocument) => {
  const {
    setIsLoading,
    leadData,
    getTaskData,
    // isCurrentDocument,
  } = props;

  const [selectedDocument, setSelectedDocument] = useState<any>(undefined);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [documentListCat, setDocumentListCat] = useState([] as any);
  const [filterDocumentList, setFilterDocumentList] = useState([]) as any;
  const [isDocumentAvailable, setIsDocumentAvailable] = useState(false)
  const [bankList, setBankList] = useState([]);
  const [uploadDocumentList, setUploadDocumentList] = useState({ sucess: [], failed: [] } as any);
  const [popupVisible, setPopupVisible] = useState(false);
  const dispatch = useDispatch();

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    getDocumentList();
    getDocumentBankData();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const getDocumentList = (isLoading = true) => {
    setIsLoading(true);
    API_SERVICE.listDocumentV2(leadData?.caseDetails?.caseId)
      .then(({ data }) => {
        if (data) {
          const docConfig = data?.payload?.documentConfigDetails
          setDocumentListCat(docConfig);
          setFilterDocumentList(docConfig);
          let tmpIsDocAvailable = false
          docConfig?.map((item: any) => {
            item?.documentTypeWithUploadedDetails.map((data: any) => {
              if (data && data?.documentDetails != null) {
                tmpIsDocAvailable = true

              }
            })
          });
          setIsDocumentAvailable(tmpIsDocAvailable)
        }
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => setIsLoading(false));
  };

  const handleSearch = (value: any) => {
    let documentTypeNameFilter = documentListCat?.map((item: any) => {
      const filterData = item?.documentTypeWithUploadedDetails?.filter(
        (data: any) => {
          return data?.documentTypeName
            ?.toLowerCase()
            ?.includes(value?.searchParam?.toLowerCase());
        }
      );
      return { ...item, documentTypeWithUploadedDetails: filterData };
    });
    let getDocumentCategroy = documentTypeNameFilter?.filter((item: any) => {
      return item?.documentTypeWithUploadedDetails?.length > 0;
    });
    setFilterDocumentList(getDocumentCategroy);
  };

  const actionOnDelete = (doc: any) => {
    setIsLoading(true);
    API_SERVICE.deleteDocument(
      doc.documentDetails.businessId,
      doc.documentDetails.businessDocumentId
    )
      .then(({ data }) => {
        getDocumentList();
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        setIsLoading(false);
      });
  };

  const getDocumentBankData = () => {
    const masterKey = 'BANK_NAME';
    //   API_SERVICE.getDocumentBankList(masterKey).then(({ data }) => {
    //     if (data?.payload) {
    //       const updtedData: any = [];
    //       data?.payload?.filter((item: any) => {
    //         updtedData.push({
    //           label: item.value,
    //           value: item.id
    //         });
    //       });
    //       setBankList(updtedData);
    //     }
    //   }).catch((e) => {
    //     notification.error({ message: API_SERVICE.handleErrors(e) });
    //   })
  }

  const renderDocumentSearch = () => {
    return (
      <Form
        layout="inline"
        style={{ marginBottom: "10px" }}
        onFinish={handleSearch}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ display: 'flex' }}>
            <Form.Item name="searchParam">
              <Input
                size="large"
                style={{ borderRadius: "8px" }}
                // prefix={<img src={iconSearch} alt="" width={17} />}
                placeholder="Search Name"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="dynamic-btn-primary"

              >
                Search
              </Button>
            </Form.Item>
          </div>
          {isDocumentAvailable && <div className="border-file-icon">
          </div>
          }
        </div>
      </Form>
    )
  };

  const downloadPopup = () => {
    console.log('::data');
    return (
      <Modal className="model-document-upload" visible={popupVisible} footer={null} onCancel={() => {
        setPopupVisible(false)
        setUploadDocumentList({ sucess: [], failed: [] });
      }} mask={false}>
        <Row className="header">
          <Col span={24} className="header-title">
            Uploaded {uploadDocumentList.sucess.length} out of {Number(uploadDocumentList.sucess.length) + Number(uploadDocumentList.failed.length)} Files
          </Col>
        </Row>
        {uploadDocumentList?.sucess?.map((items: any, index: any) => {
          return (
            <Row className="document-content" key={`doc_${index}`}>
              <Col span={2} className="doc-cols">
                {/* <img src={imgDocument} /> */}
              </Col>
              <Col span={13} className="doc-cols">
                <span className="document-name">{items?.name}</span>
              </Col>
              <Col span={6} className="doc-cols">
                <div className="document-size">{items?.size}KB</div>
              </Col>
              <Col span={3} className="doc-cols">
                {items.status === 'Done' ? (
                  <>
                    {/* <img src={imgTickRight} height={30} width={30} /> */}
                  </>
                ) : (
                  <>
                    {/* <img className="loading-img" src={imgLoading} height={30} width={30} /> */}
                  </>
                )}
              </Col>
            </Row>
          );
        })}
        {uploadDocumentList?.failed?.map((items: any, index: any) => {
          return (
            <Row className="document-content" key={`doc_${index}`}>
              <Col span={2} className="doc-cols">
                {/* <img src={imgDocument} /> */}
              </Col>
              <Col span={13} className="doc-cols">
                <span className="document-name">{items?.name}</span>
              </Col>
              <Col span={6} className="doc-cols">
                <div className="document-size">{items?.size}KB</div>
              </Col>
              <Col span={3} className="doc-cols">
                {/* <img src={wrong} height={30} width={30} /> */}
              </Col>
            </Row>
          );
        })}
      </Modal>
    );
  };

  return (
    <>
      <div className="document-upload">{downloadPopup()}</div>
      {renderDocumentSearch()}
      <div>
        {!isMobile &&
          <Row style={{ paddingInline: "16px" }}>
            <Col span={7}>{"Name"}</Col>
            <Col span={7}>{"File Name"}</Col>
            <Col span={4} className="h-center">
              {"Format"}
            </Col>
            <Col span={3} className="h-center">
              {"Max Size"}
            </Col>
            <Col span={3} style={{ textAlign: "center" }}>
              {"Actions"}
            </Col>
          </Row>
        }

        {filterDocumentList?.map((item: any, indexCat: number) => {
          return (
            <DocCategoryV2
              documentCategoryList={item}
              getDocumentList={getDocumentList}
              bankList={bankList}
            />
          );
        })}
      </div>
    </>
  );
};

export default Document;
