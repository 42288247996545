import React, { useEffect, useState } from "react";
import { Drawer, Form, Typography } from "antd";
import StepDetails from "./StepDetails/StepDetails";
import AddRules from "./AddRules";
import AddFieldType from "./AddFieldType";
import AddBasicInfo from "./AddBasicInfo";
import "../../properties.scss";
import { setScreenTitle } from "../../../../shared/redux/common-reducer";
import { useDispatch } from "react-redux";

const { Title } = Typography;

interface IDrawerFile {
    setShowAddPropery: any;
    editPropertyData: any;
    getPropertyList: any;
    setSpecificValue:any;
    specificValue:any;
    setShowValue:any;
    setPassObjectForName:any;
    setEditPropertyData:any;
}

const DrawerFile: React.FC<IDrawerFile> = (props: IDrawerFile) => {
    const { setShowAddPropery,setPassObjectForName,specificValue,setShowValue,setSpecificValue, editPropertyData, getPropertyList,setEditPropertyData } = props

    const [selectedStage, setSelectedStage] = useState(0);
    const [formData1, setFormData1] = useState(null as any);
    const [formData2, setFormData2] = useState(null as any);
    const [objectList, setObjectList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        console.log("setSpecificValue",setSpecificValue);
        if (Object.keys(editPropertyData ?? {})?.length > 0) {
            setIsEdit(true)
            const editdata = {
                labelInput: editPropertyData?.displayName,
                description: editPropertyData?.description,
                fieldType: editPropertyData?.type,
                propertyType: editPropertyData?.type === "OBJECT" ? "object" : "simple",
                children: editPropertyData?.children,
                propertyList: editPropertyData?.objectTemplate?.id,
            }
            console.log("editPropertyData",editPropertyData);
            setFormData1(editdata)
            setFormData2(editdata)
            dispatch(setScreenTitle('Edit property'))
        } else {
            dispatch(setScreenTitle('Create a new property'))
        }
        setSelectedStage(1)
    }, [])
console.log("form",formData1);
    const renderStep = () => {
        if (selectedStage === 1) {
            return <AddBasicInfo
                selectedStage={selectedStage}
                specificValue={specificValue}
                setSelectedStage={setSelectedStage}
                setAddPropery={setShowAddPropery}
                setFormData1={setFormData1}
                formData1={formData1}
                setObjectList={setObjectList}
                objectList={objectList}
                setEditPropertyData={setEditPropertyData}
                isEdit={isEdit}
            />
        }
        else if (selectedStage === 2) {
            return <AddRules
                selectedStage={selectedStage}
                specificValue={specificValue}
                setShowValue={setShowValue}
                setSelectedStage={setSelectedStage}
                setPassObjectForName={setPassObjectForName}
                setSpecificValue={setSpecificValue}
                setAddPropery={setShowAddPropery}
                formData1={formData1}
                formData2={formData2}
                isEdit={isEdit}
                editPropertyData={editPropertyData}
                getPropertyList={getPropertyList} />
        }
    }

    return (
        <>
         <style>{`
         .drawer-layout .drawer-form {
             max-width: 100%;
        } .ant-form .ant-typography {
            float: left !important;
            text-align: left !important;
            width: 85%;
        }
            .ant-row{
                width: 100%;
            }
         }`
        }</style>

            <div className={"layout-main"} style={{ marginTop: "15px" }}>
                <Title level={5} style={{ padding: "17px" }}>
                    {/* {`${isEdit ? "EDIT" : "CREATE A NEW"} PROPERTY`} */}
                    {selectedStage == 1 ? 'Basic Info' : selectedStage == 2 ? 'Select Property Rules' : ''}
                </Title>
                <div className="drawer-layout" >
                    <div className="drawer-form">
                        {/* <StepDetails
                            steps={["BASIC INFO", "FIELD TYPE", "RULES"]}
                            selectedStep={selectedStage}
                        /> */}
                        <div style={{ marginTop: "5px" }}>
                            {renderStep()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DrawerFile;
