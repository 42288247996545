import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const incompleteDisbursementSlice = createSlice({
  name: "IncompleteDisbursementReducer",
  initialState: { pageNo: 1, pageSize: 10 },
  reducers: {
    setIncompleteDisbursementPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setIncompleteDisbursementPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
 }
});

export const { setIncompleteDisbursementPageNo, setIncompleteDisbursementPageSize } = incompleteDisbursementSlice.actions;
export default incompleteDisbursementSlice.reducer;