import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Tabs, notification } from "antd";
// import Decisioning from "./Decisioning";
// import ProductSteps from "./ProductSteps";
// import ListLeadDetails from "./LenderDisbursed/ListLeadDetails";
import { useSelector } from "react-redux";
import { API_SERVICE } from "../../../shared/services/api-services";
import "./leadDetail.scss"
import ProductSteps from "./ProductStep";
import Document from "./Document/Document";
import Decisioning from "./Decisioning";


interface ILeadDetailNewTabs {
    leadData: any;
    getJSONSchema: any;
    goToDocument: any;
    setGotoDocument: any;
    setLoading: any;
    // isConnectorEditForm:boolean;
}

const LeadDetailNewTabs: React.FC<ILeadDetailNewTabs> = (
    props: ILeadDetailNewTabs
) => {
    const {
        leadData,
        getJSONSchema,
        goToDocument,
        setGotoDocument,
        setLoading,
        // isConnectorEditForm
    } = props;
    const [currentStage, setCurrentStage] = useState("");
    const [currentSteps, setCurrentSteps] = useState("")
    const [getTaskData, setGetTaskData] = useState({} as any);
    const [productWorkflowConfig, setProductWorkflowConfig] = useState({} as any);
    const [disposition, setDisposition] = useState([] as any);
    const [dispositionList, setDispositionList] = useState([] as any);
    const [isListDecisioning, setIsListDecisioning] = useState(false);
    const [selectedStageDetail, setSelectedStageDetail] = useState({} as any);
    const [showDecisioning, setShowDecisioning] = useState(false);

    useEffect(() => {
        fetchProductWorkflowById(leadData?.masterProductId);
    }, []);

    const fetchProductWorkflowById = (id: string) => {
        setLoading(true);
        API_SERVICE.getAssistedJourneyDdlData(id)
            .then(({ data }) => {
                setProductWorkflowConfig(data?.payload?.productWorkflowConfig ?? {});
                setDisposition(data?.payload?.dispositions ?? []);
                const firstStage =
                    data?.payload?.productWorkflowConfig?.stages?.at(0) ?? [];
                stageOnChange(
                    firstStage?.key,
                    data?.payload?.productWorkflowConfig,
                    data?.payload?.dispositions
                );
                console.log('lead', leadData)
                getNextTask(
                    {
                        processInstanceId: leadData?.processInstanceId,
                        clientType: "WEB",
                    },
                    data?.payload?.productWorkflowConfig,
                    data?.payload?.dispositions,
                    false
                );
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e)
            }).finally(() => setLoading(false));
    };

    const getNextTask = (
        payload: any,
        productWorkflow: any = productWorkflowConfig,
        dispositionData: any = disposition,
        isCallGetLeadDetails: boolean = true,
    ) => {
        setLoading(true);
        API_SERVICE.getNextTaskV2(payload)
            .then(({ data }) => {
                setGetTaskData(data?.payload ?? {})
            })
            // .then(({data}) => {
            //     stageOnChange(
            //         data?.payload?.productFormMetadata?.stageKey,
            //         productWorkflow,
            //         dispositionData,
            //         data?.payload ?? {}
            //     );
            //     setCurrentStage(data?.payload?.productFormMetadata?.stageKey);
            //     stepOnChange(
            //         data?.payload?.productFormMetadata?.stepKey,
            //         data?.payload ?? {}
            //     );
            // }
            //     setGetTaskData(data?.payload ?? {})
            // )
            .catch((e: any) => {
                API_SERVICE.handleErrors(e)
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const stageOnChange = (key: any, data: any, disposition: any[], tmpData: any = getTaskData) => {
        // setGotoDocument("");
        // setProductWorkflowConfig(data);
        setCurrentStage(key);
        const selectedStageTmp = data?.stages?.find((item: any) => {
            return item?.key == key;
        });
        setSelectedStageDetail(selectedStageTmp);
        const displayDispositionList = disposition?.find((item: any) => {
            return item?.stageKey == key;
        });
        setDispositionList(displayDispositionList?.disposition1And2 ?? []);
        if ((selectedStageTmp?.steps?.length ?? 0) > 0) {
            for (let index = 0; index < selectedStageTmp?.steps?.length; index++) {
                const stepkey = selectedStageTmp?.steps[index];
                if (!stepkey?.disableConfiguration) {
                    setCurrentSteps(stepkey?.key);
                    break;
                }
            }
        }
    };


    const stepOnChange = (key: any, tmpData: any = getTaskData) => {
        setCurrentSteps(key);
        setGetTaskData(tmpData)
    };

    const renderSteps = (item: any) => {
        return (
            <>
                <Tabs
                    onChange={(key) => stepOnChange(key)}
                    activeKey={currentSteps}
                    className="lender-sub-tabs"
                >
                    {item?.steps
                        ?.map((step: any) => {
                            return (
                                <Tabs.TabPane
                                    key={step?.key}
                                    tab={step?.name}
                                    style={{ paddingInline: "10px" }}
                                >
                                    <ProductSteps
                                        // getJSONSchema={getJSONSchema}
                                        formJsonSchema={JSON.stringify(step?.form?.definition)}
                                        currentSteps={currentSteps}
                                        getTaskData={getTaskData} />
                                </Tabs.TabPane>
                            );
                        })}
                </Tabs>
            </>
        );
    };

    const renderDecisioningOutput = () => (
        <Modal
            title="Decisioning Output"
            width={700}
            visible={showDecisioning}
            okText="OK"
            closable={false}
            maskClosable={false}
            footer={false}
        >
            <div className="">
                {/* <ListLeadDetails
                    leadData={leadData}
                    showDecisioning={showDecisioning}
                    setShowDecisioning={setShowDecisioning}
                /> */}
            </div>
        </Modal>
    );

    return (
        <>
            <div style={{ width: "100%", overflow:"auto" }}>
                <Tabs
                    style={{ padding: "10px" }}
                    activeKey={currentStage}
                    onChange={(key) =>
                        stageOnChange(key, productWorkflowConfig, disposition)
                    }
                >
                    {productWorkflowConfig?.stages
                        ?.filter((item: any) => (item?.key != "loginStage") && (item?.key != "lenderDecisionStage"))
                        ?.map((tab: any) => {
                            return (
                                <Tabs.TabPane
                                    key={tab?.key}
                                    tab={tab?.name}
                                // disabled={
                                //   tab?.key !== "documentStage" &&
                                //   tab?.key !== "prospectStage" &&
                                //   tab?.key !== getTaskData?.productFormMetadata?.stageKey
                                // }
                                >
                                    {(tab?.steps?.length ?? 0) > 0 ? (
                                        renderSteps(tab)
                                    ) : tab?.journeyBuilderConfiguration?.showDocumentList ? (
                                        <Document
                                            setIsLoading={setLoading}
                                            leadData={leadData}
                                            getTaskData={getTaskData}
                                        // isCurrentDocument={isCurrentDocument}
                                        />
                                    ) : tab?.key == "decisioningStage" ? (
                                        <Decisioning
                                            setIsLoading={setLoading}
                                            leadData={leadData}
                                        />
                                    ) : ""}
                                </Tabs.TabPane>
                            );
                        })}
                </Tabs>
            </div>
        </>
    );
};
export default LeadDetailNewTabs;
