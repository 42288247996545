import { Divider, Form, List, notification, Space, Typography } from "antd";
import FormInput from "../../../shared/component/FormInput/FormInput";
import FormDatePicker from "../../../shared/component/FormDatePicker/FormDatePicker";
import FormButton from "../../../shared/component/FormButton/FormButton";
import { GSTIN_REGEX, NOONLY_SPECIALCHAR_REGEX, PINCODE_REGEX } from "../../../shared/AppConstants";
import { useEffect, useState } from "react";
import { API_SERVICE } from "../../../shared/services/api-services";
import { downloadDocument } from "../../../shared/Utility/Utility";
import moment from "moment";


interface ICreateInvoice {
    setOpenCreateInvoice: (value: boolean) => void;
    getIncompleteDisbursalList: any;
    setInvoiceDetailsValue: any
    invoiceDetailsValue: any;
    startDate: any
    endDate: any
    tenantBusinessInfoId: any
    loanProductIds: any;
    lenderIds: any;
    setSelectedDisLendIds: any;
    setTabKey:any;
}

const { Title } = Typography;

const CreateInvoice: React.FC<ICreateInvoice> = (props: ICreateInvoice) => {
    const {
        setOpenCreateInvoice,
        getIncompleteDisbursalList,
        setInvoiceDetailsValue,
        invoiceDetailsValue,
        startDate,
        endDate,
        tenantBusinessInfoId,
        loanProductIds,
        lenderIds,
        setSelectedDisLendIds,
        setTabKey,
     } = props
    const [invoiceDetailsForm] = Form.useForm() as any
    const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [disbursedBy, setDisbursedBy] = useState("")
    const [products, setProducts] = useState("")
    const [saasFee, setSaasFee] = useState(0)
    const [showDateFld, setShowDateFld] = useState(false)
    
    useEffect(() => {
        if (invoiceDetailsValue?.tenantInvoiceDetailsDto) {
            invoiceDetailsForm.setFieldsValue({
                dsaName: invoiceDetailsValue?.tenantInvoiceDetailsDto?.tenantName,
                address: invoiceDetailsValue?.tenantInvoiceDetailsDto?.address,
                pincode: invoiceDetailsValue?.tenantInvoiceDetailsDto?.pincode,
                city: invoiceDetailsValue?.tenantInvoiceDetailsDto?.city,
                state: invoiceDetailsValue?.tenantInvoiceDetailsDto?.state,
                gstin: invoiceDetailsValue?.tenantInvoiceDetailsDto?.gstIn,
                invoiceNumber: invoiceDetailsValue?.invoiceNo,
                invoiceDate: invoiceDetailsValue?.invoiceDate,
                saasFee: invoiceDetailsValue?.saasFeePercent                                
            }); 
            setShowDateFld(startDate != "" && endDate != "")            
        }
        setTotalInvoiceAmount((invoiceDetailsValue?.totalInvoiceAmount))    
            const tmpDisbursedBy = invoiceDetailsValue?.lenderDetailsSet.map((item:any)=>{
                return item.lenderName
             })
            setDisbursedBy(tmpDisbursedBy.toString())
            const tmpProducts = invoiceDetailsValue?.loanProductDetailsSet.map((item:any)=>{
                return item.loanProductName
             })
            setProducts(tmpProducts.toString())
            
    }, [invoiceDetailsValue]);

    const handleSaasFeeChange=(saasFeePercent:any)=>{
        let disbursedAmount = invoiceDetailsValue?.totalDisbursedAmount
        setTotalInvoiceAmount((disbursedAmount * saasFeePercent)/100)
        setSaasFee(saasFeePercent)
}
       

    const data = [
        <FormInput
            name="dsaName"
            label="DSA Name"
            isBorder
            isRequired
            rules={[
                {
                    pattern: NOONLY_SPECIALCHAR_REGEX,
                    message: "Please enter DSA Name"
                }
            ]}
        />,
        <FormInput
            name="address"
            label="Address"
            isBorder
            isRequired
            rules={[
                {
                    pattern: NOONLY_SPECIALCHAR_REGEX,
                    message: "Please enter address"
                }
            ]}
        />,
        <FormInput
            name="pincode"
            label="Pincode"
            rules={[
                {
                    pattern: PINCODE_REGEX,
                    message: "Please enter vaild pincode"
                }
            ]}
            isBorder
            isRequired
        />,
        <FormInput
            name="city"
            label="City"
            isBorder
            isRequired
            rules={[
                {
                    pattern: NOONLY_SPECIALCHAR_REGEX,
                    message: "Please enter city"
                }
            ]}
        />,
        <FormInput
            name="state"
            label="State"
            isBorder
            isRequired
            rules={[
                {
                    pattern: NOONLY_SPECIALCHAR_REGEX,
                    message: "Please enter valid state"
                }
            ]}
        />,
        <FormInput
            name="gstin"
            label="GSTIN"
            rules={[
                {
                    pattern: GSTIN_REGEX,
                    message: "Please enter valid GSTIN"
                }
            ]}
            isBorder
            isRequired
        />,
        <Title level={5}>Invoice Details</Title>,
        <div />,
        <div />,
        <FormInput
            name="invoiceNumber"
            label="Invoice Number"
            rules={[
                {
                    pattern: NOONLY_SPECIALCHAR_REGEX,
                    message: "Please enter valid invoice number"
                }
            ]}
            isBorder
            isRequired
        />,
        <FormInput
            name="saasFee"
            label="SaaS Fee %"
            rules={[
                () => ({
                    validator(_, value) {
                      if (!value && value != 0 ) {
                        return Promise.reject();
                      }
                      if (!/^\d{0,3}(\.\d{0,2}){0,1}$/.test(value)) {
                        return Promise.reject(new Error("Please enter valid saas fee%"));
                      }
                      if (value > 100 || value === "."){
                        return Promise.reject("Please enter valid saas fee%");
                      }
                      if (isNaN(value)) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  })                            
            ]}
            onChange={(event)=>{handleSaasFeeChange(event.target.value)}}
            isBorder
            isRequired
        />,
        <FormDatePicker
            name="invoiceDate"
            label="Invoice Date"
            isBorder
            isRequired
            rules={[
                {
                    message: "Please enter invoice date"
                }
            ]}

        />,
    ]

    const handleSubmit = (values: any) => {
        const tenantInvoiceDetailsDto = {
            tenantId: tenantBusinessInfoId,
            tenatntName: values?.dsaName,
            address: values?.address,
            pincode: values?.pincode,
            city: values?.city,
            state: values?.state,
            gstIn: values?.gstin
        }
        const disbursalLenderInfoIds = setSelectedDisLendIds
        const payload = {
            tenantInvoiceDetailsDto: tenantInvoiceDetailsDto,
            disbursalLenderInfoIds: disbursalLenderInfoIds,
            saasFeePercent: values?.saasFee,
            invoiceNo: values?.invoiceNumber,
            invoiceDate: values?.invoiceDate?.format("YYYY-MM-DD")
        }
        API_SERVICE.getInvoiceCreate(payload)
            .then(({ data }) => {
                setInvoiceDetailsValue(data?.payload)
                setOpenCreateInvoice(false)
                notification.success({message:"Invoice created successfully"})
                setTabKey("INVOICES")
                downloadDocument(data?.payload?.invoiceSummary?.excelInvoiceDocumentId)                
                getIncompleteDisbursalList(1, 10, tenantBusinessInfoId, startDate, endDate, loanProductIds, lenderIds)               
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
            .finally(() => {

            });
    }
    return (
        <>
            <Title level={5} style={{ padding: "15px" }}>Create Invoice</Title>
            <Divider style={{ margin: 0 }} />
            <Space direction="vertical" style={{ padding: "15px", gap: "20px" }}>
                <Space>
                    <div className="disbursal-details">
                        <span style={{ fontSize: "20px", color: "#000000BF" }}>{invoiceDetailsValue?.selectedDisbursal}</span>
                        <span style={{ fontSize: "16px" }}>Selected Disbursal</span>
                    </div>
                    {showDateFld &&(
                        <>
                         <div className="disbursal-details">
                            <span style={{ fontSize: "20px", color: "#000000BF" }}>{startDate ? moment(startDate ?? "")?.format("DD/MM/YY") : ""}</span>
                            <span style={{ fontSize: "16px" }}>From Date</span>
                        </div>
                        <div className="disbursal-details">
                            <span style={{ fontSize: "20px", color: "#000000BF" }}>{endDate ? moment(endDate ?? "")?.format("DD/MM/YY") : ""}</span>
                            <span style={{ fontSize: "16px" }}>To Date</span>
                        </div>
                        </>                       
                )}
                    <div className="disbursal-details">
                        <span style={{ fontSize: "20px", color: "#000000BF" }}>{invoiceDetailsValue?.totalDisbursedAmount}</span>
                        <span style={{ fontSize: "16px" }}>Total Amount</span>
                    </div>
                </Space>
                <Space>
                    <div className="lender-details">
                        <span>Disbursed By :{disbursedBy} </span>
                    </div>
                    <div className="lender-details">
                        <span>Product : {products}</span> 
                    </div>
                </Space>
                <div>
                    <Title level={5}>DSA Tenent Details</Title>
                    <Form layout="vertical" onFinish={(values) => handleSubmit(values)}
                        form={invoiceDetailsForm}>
                        <List
                            grid={{ gutter: 16, column: 3 }}
                            dataSource={data}
                            renderItem={(item, index) => (
                                <List.Item>
                                    {item}
                                </List.Item>
                            )}
                        />
                        <div className="lender-details">
                        <span>Invoice Amount : </span>{totalInvoiceAmount}
                        </div>
                        <Space>
                            <FormButton
                                label="Cancel"
                                size="large"
                                onclick={() => setOpenCreateInvoice(false)}
                                style={{ height: "58px", width: "140px", marginTop: "15px" }}
                            />
                            <FormButton
                                size="large"
                                type="primary"
                                label="Save & Download"
                                // onclick={handleSubmit}
                                htmlType="submit"
                                style={{ height: "58px", marginTop: "15px" }}
                            />
                        </Space>
                    </Form>
                </div>
            </Space>
        </>
    )
};

export default CreateInvoice;