import { Button, Popconfirm, Tooltip } from "antd";
import iconDelete from "../../../assets/images/delete.svg";
import iconEdit from "../../../assets/images/edit.svg";
import iconUp from "../../../assets/images/arrowUp.svg";
import iconDown from "../../../assets/images/arrowDown.svg";
import iconUpload from "../../../assets/images/upload-logo.svg";
import iconAddDuplicate from "../../../assets/images/addDuplicate.svg";
import iconEditBlack from "../../../assets/images/editBlack.svg";
import iconPlus from "../../../assets/images/plusIcon.svg";
import iconMinus from "../../../assets/images/minusIcon.svg";
import iconBlackCopy from "../../../assets/images/copyImg.svg";
import iconClickFinger from "../../../assets/images/clickFinger.svg"
import iconAssignProduct from "../../../assets/images/assignProduct.svg"
import iconInfo from "../../../assets/images/info.svg"
import iconCloudDownload from "../../../assets/images/cloudDownload.svg";
import iconCloudUpload from "../../../assets/images/cloudUpload.svg";
import iconPlay from "../../../assets/images/play.svg";
import iconEye from "../../../assets/images/eye.svg";
import iconEyeGrey from "../../../assets/images/eye-grey.svg"
import iconExcelDownload from "../../../assets/images/ExcelDownload.svg"
import iconPdfUpload from "../../../assets/images/PDFUpload.svg"
import iconDrillDown from "../../../assets/images/DrillDown.svg"
import iconViewPdf from "../../../assets/images/ViewPdf.svg"
import "./FormIconButton.scss"

export type iconType = "delete" | "edit" | "up" | "down" | "upload" | "info" | "addDuplicate"| "editBlack" | "plus" | "minus" | "copyBlack" | "clickFinger" | "assignProduct" | "cloudDownload" | "cloudUpload" | "play" | "eye" |"eyeGrey" | "excelDownload" | "pdfUpload"|"drillDown" |"viewPdf";

interface IFormIconButton {
    type: iconType;
    onClick?: any;
    isShowPopconfirm?: boolean;
    className?: string;
    isDisabled?: boolean;
    tooltipTitle?: string;
}

const FormIconButton = (props: IFormIconButton) => {
    const { type, onClick, isShowPopconfirm, className, isDisabled, tooltipTitle } = props;

    const icons: any = {
        delete: iconDelete,
        edit: iconEdit,
        up: iconUp,
        down: iconDown,
        upload: iconUpload,
        info: iconInfo,
        addDuplicate: iconAddDuplicate,
        editBlack: iconEditBlack,
        plus: iconPlus,
        minus:iconMinus,
        copyBlack: iconBlackCopy,
        clickFinger: iconClickFinger,
        assignProduct: iconAssignProduct,
        cloudDownload: iconCloudDownload,
        cloudUpload: iconCloudUpload,
        play: iconPlay,
        eye: iconEye ,
        eyeGrey: iconEyeGrey,
        excelDownload:iconExcelDownload,
        pdfUpload:iconPdfUpload,
        drillDown:iconDrillDown,
        viewPdf:iconViewPdf
    };

    const renderIcon = () => {
        return <img src={icons[`${type}`]} width="20px" height="20px" alt="" />;
    }

    const renderDelete = () => {
        return <Popconfirm
            placement="topRight"
            title="Are you sure to delete?"
            okText="Yes"
            cancelText="No"
            className="remove"
            onConfirm={onClick}
        >
            <Button
                className="btn-border-none"
                icon={renderIcon()}
                disabled={isDisabled}
            />
        </Popconfirm>
    }

    const renderBtn = () => {
        return <Tooltip title={tooltipTitle}> <Button
            className={`btn-border-none ${className}`}
            icon={renderIcon()}
            onClick={onClick}
            disabled={isDisabled}
        />
        </Tooltip>
    }

    return <>
        {isShowPopconfirm ? renderDelete() : renderBtn()}
    </>;
};

export default FormIconButton;
