import { Button, Checkbox, Collapse, Divider, Form, Space } from "antd";
import React, { useEffect, useState } from "react";
import FormInput from "../../../../shared/component/FormInput/FormInput";
import { API_SERVICE } from "../../../../shared/services/api-services";
import "../ProductProperty.scss";
import FormButton from "../../../../shared/component/FormButton/FormButton";
//import { CheckboxValueType } from "antd/es/checkbox/Group";

type ISimpleComplexeProperty = {
  tabChangeKey: any;
  setIsTabChange: any;
  isTabChange: any;
  setSelectedSimpleProperty: any;
  setSelectedComplexProperty: any;
  selectedSimpleProperty: any;
  selectedComplexProperty: any;
  productId: any;
};

const { Panel } = Collapse;

const SimpleComplexeProperty: React.FunctionComponent<
  ISimpleComplexeProperty
> = (props: ISimpleComplexeProperty) => {
  const {
    tabChangeKey,
    isTabChange,
    setIsTabChange,
    setSelectedComplexProperty,
    setSelectedSimpleProperty,
    selectedSimpleProperty,
    selectedComplexProperty,
    productId
  } = props;
  const [propertyList, setPropertyList] = useState([] as any);
  const [simpleChecked,setSimpleChecked] = useState([] as any);
  const [complexChecked,setComplexChecked] = useState([] as any);

  useEffect(() => {
    masterPropertyList();
    setIsTabChange(false);
  }, [isTabChange]);

  const masterPropertyList = (searchParam: any = null) => {
    const params = {
      isComplex: tabChangeKey == "COMPLEX",
      name: searchParam,
      pageSize: 100,
    };
    API_SERVICE.getPropertyList(params)
      .then(({ data }) => {
        const displayNameList = data?.payload?.content?.map((item: any) => {
          return {
            key: item?.id,
            value: item?.displayName,
            children: item?.children,
          };
        });
        setPropertyList(displayNameList);
        getProductPropertyList();
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  };

  const getProductPropertyList = () => {
    API_SERVICE.getProductProperty(productId)
      .then(({ data }) => {
        const simple = data?.payload?.map((item: any) => {
          return item?.id;
        });
        setSelectedSimpleProperty(simple);
        setSimpleChecked(simple);
        const complexFilter = data?.payload?.filter(
          (item: any) => item?.type == "OBJECT"
        );
        const complex = complexFilter?.map((item: any) => {
          return item?.id;
        });
        setSelectedComplexProperty(complex);
        setComplexChecked(complex)
      })
      .catch((e: any) => API_SERVICE.handleErrors(e));
  }

  console.log("setSimpleChecked",simpleChecked)
  console.log("setSelectedSimpleProperty",selectedSimpleProperty)
  const renderCheckboxList: any = (children: any, isChild: boolean = false) => {
    return (
      <>
        {children?.map((item: any, index: number) => {
          const isLast = index == children?.length - 1;
          return (
            <div
              style={{ display: "flex", marginLeft: isChild ? "10px" : "4px" }}
            >
              <div
                className="line-vertical"
                style={isLast ? { height: "11px" } : {}}
              />
              <div>
                <div className="child-view">
                  <div className="line-horizontal" />
                  {item?.displayName}
                </div>
                {(item?.children?.length ?? 0) > 0 && (
                  <p
                    style={{
                      marginLeft: "3px",
                      marginTop: "5px",
                    }}
                  >
                    {renderCheckboxList(item?.children, true)}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className="decisioning-form">
      <FormInput
        label="Search"
        name="search"
        classNameForm="space-input"
        onChange={(e: any) => {
          masterPropertyList(e?.target?.value);
        }}
        isBorder={true}
      />
      <Form.Item name="properties">
        <div
          className="preview-box"
          style={{ marginLeft: "0px", height: "225px" }}
        >
          <div className="preview-name-div">
            <label>Properties</label>
          </div>
          <div style={{ height: "200px", overflow: "auto" }}>
            <ul style={{ width: "100%" }}>
              {propertyList.map((data: any, index: number) => {
                return (
                  <li style={{ listStyleType: "none", marginLeft: "-15px" }}>
                    <Collapse expandIconPosition="right" ghost size="small">
                      <Panel
                        showArrow={
                          (data?.children?.length ?? 0) > 0 &&
                          tabChangeKey == "COMPLEX"
                        }
                        collapsible={
                          (data?.children?.length ?? 0) > 0 &&
                            tabChangeKey == "COMPLEX"
                            ? "icon"
                            : "disabled"
                        }
                        header={
                          <>
                            {tabChangeKey == "SIMPLE" ? (
                              <Checkbox
                                checked={selectedSimpleProperty?.some(
                                  (id: any) => id == data?.key
                                )}
                                disabled={simpleChecked?.some(
                                  (item: any) => item == data?.key)}
                                onChange={(e: any) => {                               
                                  let tmp = [...selectedSimpleProperty];
                                  if (
                                    selectedSimpleProperty?.includes(data?.key)
                                  ) {
                                    tmp = selectedSimpleProperty?.filter(
                                      (item: any) => item != data?.key
                                    );
                                  } else {
                                    tmp.push(data?.key);
                                  }
                                  setSelectedSimpleProperty(tmp);
                                }}
                              >
                                {data.value}
                              </Checkbox>
                            ) : (
                              <Checkbox
                                checked={selectedComplexProperty?.some(
                                  (id: any) => id == data?.key
                                )}
                                disabled={complexChecked?.some(
                                  (item: any) => item == data?.key)}
                                value={data?.key}
                                onChange={(e: any) => {
                                  let tmp = [...selectedComplexProperty];
                                  if (
                                    selectedComplexProperty?.includes(data?.key)
                                  ) {
                                    tmp = selectedComplexProperty?.filter(
                                      (item: any) => item != data?.key
                                    );
                                  } else {
                                    tmp.push(data?.key);
                                  }
                                  setSelectedComplexProperty(tmp);
                                }}
                              >
                                {data.value}
                              </Checkbox>
                            )}
                          </>
                        }
                        key={index}
                      >
                        {(data?.children?.length ?? 0) > 0 &&
                          tabChangeKey == "COMPLEX"
                          ? renderCheckboxList(data?.children)
                          : ""}
                      </Panel>
                    </Collapse>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Form.Item>
    </div>
  );
};

export default SimpleComplexeProperty;
