import { Button, Checkbox, Col, Form, message, notification, Row, Space, Table, Typography } from "antd";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import FormInput from "../../../shared/component/FormInput/FormInput";
import { API_SERVICE } from "../../../shared/services/api-services";
import { useDispatch, useSelector } from "react-redux";
import { setDisbursalPageNo, 
    setDisbursalPageSize} from "../../../shared/redux/disbursal-reducer";
import { RootState } from "../../../shared/redux/store";
import { numberWithCommas } from "../../../shared/Utility/Utility";
import ViewDocument from "../../../shared/component/ViewDocument/ViewDocument";

interface IDisbursals {
    tenantBusinessInfoId: any,
    totalCount: number;
    loanProductIds: any,
    lenderIds: any,
    startDate: any,
    endDate: any,
    completeDisbList: any,
    getIncompleteDisbursalList: any,
    setOpenCreateInvoice: (value: boolean) => void,
    setInvoiceDetailsValue: any,
    setSelectedDisLendIds: any,
    selectedDisbRows:[],
    disbursalStatus:any,
    tabKey:any,
    invoiceDetailsValue:any  
    setSelectedCase:any        
    selectedCase:any
    setTotalDisbursalAmount:any
    totalDisbursalAmount:any
    setTotalInvoiceAmount:any
    totalInvoiceAmount:any
    selectedRowKeys:any
    setSelectedRowKeys:any
}

const Disbursals: React.FC<IDisbursals> = (props: IDisbursals) => {
    const {
        setOpenCreateInvoice,
        loanProductIds,
        totalCount,
        lenderIds,
        startDate,
        endDate,
        tenantBusinessInfoId,
        completeDisbList,
        getIncompleteDisbursalList,
        setInvoiceDetailsValue,
        setSelectedDisLendIds,
        selectedDisbRows ,
        disbursalStatus, 
       tabKey,
       invoiceDetailsValue,
       setSelectedCase,
       selectedCase,
       totalDisbursalAmount,
       setTotalDisbursalAmount,
       setTotalInvoiceAmount,
       totalInvoiceAmount,
       selectedRowKeys,
       setSelectedRowKeys
    } = props;
    const [sasfeePercent, setSasFeePercent] = useState(0);
    const [loading, setLoading] = useState(false)
    const [openViewDocument, setOpenViewDocument] = useState(false)
    const [businessDocumentId, setBusinessDocumentId] = useState("");
    const [saasFeeForm] = Form.useForm() as any;
    const [disbursalForm] = Form.useForm() as any;
    const dispatch = useDispatch();
    const disbursal: any = useSelector((state: RootState) => state.disbursal);


    useEffect(() => {
        if (selectedDisbRows) {
         setSelectedRowKeys(selectedDisbRows);
       }
     }, []);

    useEffect(() => {
        if (tabKey === "DISBURSALS"){
            if ((tenantBusinessInfoId?.length === 0)) {
                setSelectedCase(0)
                setTotalDisbursalAmount(0)
                setTotalInvoiceAmount(0)
                setSelectedRowKeys(selectedDisbRows)
            }
            if(tenantBusinessInfoId?.length > 0) {
                setSelectedRowKeys(selectedDisbRows)
                setSasFeePercent(invoiceDetailsValue?.saasFeePercent)
                if (invoiceDetailsValue.saasFeePercent===undefined){
                    setSelectedCase(0)
                    setTotalDisbursalAmount(0)
                    setTotalInvoiceAmount(0)
                    saasFeeForm.setFieldsValue({
                        saasFee:invoiceDetailsValue?.saasFeePercent
                    })
                }
                else{
                    let invoiceDisbursalList = completeDisbList.filter((item:any) => selectedDisbRows.toString().includes(item.disbursalId));
                    let sumDisbursalAmount = 0;
                    invoiceDisbursalList?.map((item: any) => {
                        return sumDisbursalAmount += item?.disbursedAmount
                    })
                    let sasfeePercent=invoiceDetailsValue.saasFeePercent
                    let calculateInvoiceAmount = (sumDisbursalAmount *sasfeePercent) / 100;    
                    setSelectedCase(selectedDisbRows.length)
                    setTotalDisbursalAmount(sumDisbursalAmount)        
                    setTotalInvoiceAmount(calculateInvoiceAmount)
                    saasFeeForm.setFieldsValue({
                        saasFee:invoiceDetailsValue?.saasFeePercent
                    })
               }           
            }
            getIncompleteDisbursalList(disbursal.pageNo, disbursal.pageSize, tenantBusinessInfoId, startDate, endDate, loanProductIds, lenderIds, "COMPLETE");
        }
        
    }, [tenantBusinessInfoId])
    
    const handlePaginationChange = (pagination:any) => {
        let page = pagination?.pageSize != disbursal.pageSize ? 1 : pagination.current;
        dispatch(setDisbursalPageNo(page));
        dispatch(setDisbursalPageSize(pagination.pageSize));
        setSelectedCase(0)
        setTotalDisbursalAmount(0)
        setTotalInvoiceAmount(0)
        getIncompleteDisbursalList(page, pagination.pageSize, tenantBusinessInfoId, startDate, endDate, loanProductIds, lenderIds, disbursalStatus);
      };

    const column: ColumnsType<any> = [
        {
            title: "Case ID",
            dataIndex: "caseId",
            key: "caseId",
        },
        {
            title: "Disbursal ID",
            dataIndex: "disbursalId",
            key: "disbursalId",
        },
        {
            title: "Customer Name",
            dataIndex: "customerName",
            key: "customerName",
        },
        {
            title: "Product Type",
            dataIndex: "productType",
            key: "productType",
        },
        {
            title: "Lender Name",
            dataIndex: "lenderName",
            key: "lenderName",
        },
        {
            title: "Disbursal Amount",
            dataIndex: "disbursedAmount",
            key: "disbursalAmount",
            render: (value) => (
                <span>Rs. {numberWithCommas(value)}</span>
            )
        },
        {
            title: "Disbursal Date",
            dataIndex: "disbursalDate",
            key: "disbursalDate",
        },
        {
            title: "Lender Identifier",
            dataIndex: "lenderUniqueIdentifier",
            key: "uniqueIdentifier",
           
        },
        {
            title: "Proof of Disbursal",
            dataIndex: "proofOfDisbursal",
            key: "action",
            align: "center",
            width: 80,//150
            render: (value, rec) => {
                return(
                    <>
                        <FormIconButton
                        type="eye"
                        onClick={() => { 
                            if (value?.documentIdList?.at(0)) {
                                setBusinessDocumentId(value?.documentIdList?.at(0))
                                setOpenViewDocument(true);
                            }
                        }}
                    />
                </>                
            )
        }
        },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[], rec: any) => {
        setSelectedRowKeys(newSelectedRowKeys);
        let sumDisbursalAmount = 0;
        rec?.map((item: any) => {
          return sumDisbursalAmount += item?.disbursedAmount
        })
        let calculateInvoiceAmount = (sumDisbursalAmount *sasfeePercent) / 100;
        setSelectedCase(newSelectedRowKeys?.length)
        setTotalDisbursalAmount(sumDisbursalAmount)
        setTotalInvoiceAmount((sasfeePercent!=undefined)?calculateInvoiceAmount:0)
        let tmpSelectedRowIds = [] as any
        tmpSelectedRowIds.push(newSelectedRowKeys)
        setSelectedDisLendIds(tmpSelectedRowIds)
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record: any) => ({}),        
    };

    const handleChange = (event: any) => {
        let calculateInvoiceAmount = (totalDisbursalAmount * event?.target?.value) / 100;
        setTotalInvoiceAmount(calculateInvoiceAmount);
        setSasFeePercent(event?.target?.value)
    }

    const handleGenerateInvoice = () => {
        if ((sasfeePercent === 0)) {
            notification.error({ message: "Please enter Saas Fee%." });
        }
        else if (undefined === selectedRowKeys || selectedRowKeys?.length === 0) {
            notification.error({ message: "Please select any disbursal to generate invoice" });
        }
        else if (tenantBusinessInfoId.length===0){
            notification.error({ message: "You cannot generate invoice for muliple tenant at once. Please select any one teanant in the teanant filter to create invoice" });
        }
        else {
        const tenantInvoiceDetailsDto = {
            tenantId: tenantBusinessInfoId
        }
        const disbursalLenderInfoIds = selectedRowKeys
        const payload = {
            tenantInvoiceDetailsDto: tenantInvoiceDetailsDto,
            disbursalLenderInfoIds: disbursalLenderInfoIds,
            saasFeePercent: sasfeePercent
        }
        API_SERVICE.getInvoicePreview(payload)
            .then(({ data }) => {
                setInvoiceDetailsValue(data.payload)
                setOpenCreateInvoice(true)
                setSelectedDisLendIds(selectedRowKeys)
            })
            .catch((e: any) => {
                API_SERVICE.handleErrors(e);
            })
            .finally(() => {
                setLoading(false);
            });
        }
    }
    
    return (
        <>
            <Form form={disbursalForm}>
                <Table
                    columns={column}
                    className="dynamic-pagination-checkbox"
                    rowKey={(record) => record?.disbursalId}
                    dataSource={(completeDisbList)}
                    rowSelection={rowSelection}
                    size="small"
                    tableLayout="fixed"
                    pagination={{
                        position: ["bottomCenter"],
                        current:  disbursal.pageNo,
                        pageSize: disbursal.pageSize,
                        total: totalCount
                    }}
                    onChange={handlePaginationChange}
                    
                    footer={() => (
                        <div className="footer-table">
                            <span>Selected disbursal cases: {selectedCase}</span> 
                            <span>Total Disbursal Amount: Rs. { numberWithCommas(totalDisbursalAmount)}</span>
                            <span>Total Invoice Amount: Rs. {numberWithCommas(totalInvoiceAmount)}</span>
                        </div>
                    )}
                />
            </Form>
            {openViewDocument && (
                <ViewDocument
                    showPopDocument={openViewDocument}
                    setIsShowPopDocument={setOpenViewDocument}
                    businessDocumentId={businessDocumentId}
                />
            )}            
            <Form layout="vertical"
                form = {saasFeeForm}
                onFinish={() => handleGenerateInvoice()} style={{ marginBottom: "5rem" }}>
                <Space>
                    <FormInput
                        name="saasFee"
                        label="SaaS Fee %"
                        rules={[
                            () => ({
                                validator(_, value) {
                                  if (!value && value != 0 ) {
                                    return Promise.reject();
                                  }
                                  if (!/^\d{0,3}(\.\d{0,2}){0,1}$/.test(value)) {
                                    return Promise.reject(new Error("Please enter Valid saas fee%"));
                                  }
                                  if (value > 100 || value === "."){
                                    return Promise.reject("Please enter Valid saas fee%");
                                  }
                                  if (isNaN(value)) {
                                    return Promise.reject();
                                  }
                                  return Promise.resolve();
                                },
                              })                            
                        ]}
                        isRequired
                        isBorder
                        requiredLabel="saas fee"
                        style={{ width: "350px", marginBottom: "15px" }}
                        onChange={(value) => handleChange(value)}
                    />
                    <Form.Item>
                        <Button
                            type="primary"
                            size="large"
                            htmlType="submit"
                            style={{ height: "58px", marginTop: "15px" }}
                        >
                            Generate Invoice
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </>
    )
};

export default Disbursals;