import Table, { ColumnsType } from "antd/es/table";
import FormIconButton from "../../../shared/component/FormIconButton/FormIconButton";
import { useEffect, useState } from "react";
// import InvoiceDetails from "./InvoiceDetails";
import { setScreenTitle } from "../../../shared/redux/common-reducer";
import { useDispatch, useSelector } from "react-redux";
import { API_SERVICE } from "../../../shared/services/api-services";
import { RootState } from "../../../shared/redux/store";
import { setInvoiceId, setInvoicePageNo, setInvoicePageSize, setInvoiceTenantId } from "../../../shared/redux/invoice-reducer";
import AppUpload from "../../../shared/component/AppUpload/AppUpload";
import ViewDocument from "../../../shared/component/ViewDocument/ViewDocument";
import { downloadDocument } from "../../../shared/Utility/Utility";
import { Tooltip } from "antd";


interface IInvoices {
    setViewInvDisbursal: any
    tenantBusinessInfoId: string;
    loanProductIds: any;
    startDate: string;
    endDate: string;
    setIsLoading: (value: boolean) => void;
    tabKey: string;
    getInvoiceList:any,
    invoiceData:any,
    totalCount:number,
                            
}


const Invoices: React.FC<IInvoices> = (props: IInvoices) => {
    const {
        setViewInvDisbursal,
        tenantBusinessInfoId,
        loanProductIds,
        startDate,
        endDate,
        setIsLoading,
        tabKey,
        getInvoiceList,
        invoiceData,
        totalCount,
        
    } = props
    const [openViewDocument, setOpenViewDocument] = useState(false)
    const [businessDocumentId, setBusinessDocumentId] = useState("");
    const [imageUrl, setImageUrl] = useState(null as any);
    // const { pageNo, pageSize } = useSelector(
    //     (state: RootState) => state.invoice
    //   );
      const invoice: any = useSelector((state: RootState) => state.invoice);
    const dispatch = useDispatch();

    useEffect(() => {
     if (tabKey == "INVOICES" && tenantBusinessInfoId.length > 0) {
               getInvoiceList(invoice.pageNo, invoice.pageSize, tenantBusinessInfoId, loanProductIds, startDate, endDate)
        }
    }, [tenantBusinessInfoId])
    
    const handlePaginationChange = (pagination:any) => {
        let page = pagination.pageSize != invoice.pageSize ? 1 : pagination.current;
        dispatch(setInvoicePageNo(page));
        dispatch(setInvoicePageSize(pagination.pageSize));
        getInvoiceList(page, pagination.pageSize, tenantBusinessInfoId, loanProductIds, startDate, endDate);
      };

    const onAddDoc = (res: any, rec: any) => {
        const payload = {
            documentURL: res?.data,
            documentName: res?.file?.name,
            documentType: "INVOICE_DOCUMENT",
            documentSubType: "SIGNED_INVOICE_DOCUMENT",
            docOrder: 0,
            docPage: 0
        }
        setIsLoading(true)
        API_SERVICE.invoiceAddDocument(rec?.documentSetId, payload)
            .then(({ data }) => {
                addSignedDoc(data?.payload?.businessDocumentId, rec?.id)
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e)
                setIsLoading(false);
            });
    };

    const addSignedDoc = (docId: any, id: string) => {
        const payload = {
            invoiceId: id,
            signedDocumentIdSet: [docId]
        }
        API_SERVICE.invoiceAddSignedDoc(payload)
            .then(({ data }) => {
                getInvoiceList(invoice.pageNo, invoice.pageSize, tenantBusinessInfoId, loanProductIds, startDate, endDate);
            }).catch((e: any) => {
                API_SERVICE.handleErrors(e)
                setIsLoading(false);
            });
    }

    const column: ColumnsType<any> = [
        {
            title: "Invoice ID",
            dataIndex: "id",
            key: "invoiceId",
            width: 130
        },
        {
            title: "Count of Disbursals",
            dataIndex: "countOfDisbursal",
            key: "countOfDisbursals",
            width: 150
        },
        {
            title: "Invoice Date",
            dataIndex: "invoiceDate",
            key: "invoiceDate",
            width: 120
        },
        {
            title: "Tenant DSA",
            dataIndex: ["invoiceSummary", "tenantDetails", "tenantName"],
            key: "tenantDSA",
            width: 200
        },
        {
            title: "Lender",
            dataIndex: ["invoiceSummary", "lendersSet"],
            key: "lender",
            width: 200,
            render: (value: any, rec) => {
                const lenderList = rec?.invoiceSummary?.lendersSet;
                const filterLenderList = lenderList?.filter((item: any) => item?.id?.length > 0)
                let displayValue = filterLenderList?.map((x: any) => x?.lenderName)?.join(", ");
                return (
                    <Tooltip title={displayValue}>
                        <p
                            style={{
                                margin: 0,
                                width: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                        }}>
                            {displayValue}
                        </p>
                    </Tooltip>                    
                )
            }
        },
        {
            title: "Product",
            dataIndex: ["invoiceSummary", "loanProductsSet"],
            key: "product",
            width: 200,
            render: (value: any, rec) => {
                const lenderList = rec?.invoiceSummary?.loanProductsSet;
                const filterProductList = lenderList?.filter((item: any) => item?.id?.length > 0);
                let displayValue = filterProductList?.map((x: any) => x?.loanProductName)?.join(", ")
                return (
                    <Tooltip title={displayValue}>
                        <p
                            style={{
                                margin: 0,
                                width: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                        }}>
                            {displayValue}
                    </p>
                    </Tooltip>
                    
                )
            }
        },
        {
            title: "Action",
            dataIndex: ["invoiceSummary"],
            key: "action",
            width: 150,
            render: (value, rec) => {
                return (
                    <>
                        <FormIconButton
                            type="excelDownload"
                            onClick={() => { downloadDocument(value?.excelInvoiceDocumentId) }}
                            tooltipTitle="Download Excel"
                        />
                        {value?.signedInvoiceDocumentIds?.length > 0 ? (
                            <FormIconButton
                                type={"viewPdf"}
                                onClick={() => {
                                     if (value?.signedInvoiceDocumentIds?.at(0)) {
                                        setBusinessDocumentId(value?.signedInvoiceDocumentIds?.at(0))
                                        setOpenViewDocument(true);
                                    }
                                }}
                                tooltipTitle={"View PDF"}
                            />
                        ) : (
                            <AppUpload
                                acceptType={['pdf']}
                                showUploadList={false}
                                className=""
                                maxSize={10}
                                setImageUrl={setImageUrl}
                                callBack={(response: any) => {
                                    onAddDoc(response, rec)
                                }}
                                children={(
                                    <FormIconButton
                                        type={"pdfUpload"}
                                        tooltipTitle={"Upload PDF"}
                                    />
                                )}
                            />
                        )}
                        <FormIconButton
                            type="drillDown"
                            onClick={() => (handleViewInvDisbursalDetails(rec))}
                            tooltipTitle="View Disbursal"
                        />
                    </>
                )
            }
        },
    ];

    const handleViewInvDisbursalDetails = (rec: any) => {
        setViewInvDisbursal(true);
        dispatch(setInvoiceTenantId(rec?.tenantId));
        dispatch(setInvoiceId(rec?.id))
        dispatch(setScreenTitle("Invoices > Disbursals"))
    };

    return (
        <>

            <div className={"layout-main"} style={{ marginTop: "20px" }}>
                <Table
                    columns={column}
                    dataSource={invoiceData}
                    size="small"
                    pagination={{
                        position: ["bottomCenter"],
                        current:  invoice.pageNo,
                        pageSize: invoice.pageSize,
                        total: totalCount
                    }}
                    onChange={handlePaginationChange}
                />
            </div>
            <ViewDocument
                showPopDocument={openViewDocument}
                setIsShowPopDocument={setOpenViewDocument}
                businessDocumentId={businessDocumentId}
            />
        </>
    )
};

export default Invoices;