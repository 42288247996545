import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import Properties from "../properties/Properties";
import AppContent from "../appConetent/App-content";
import Reports from "../reports/Report";
import {
  PATH_APP_CONTENT,
  PATH_COUPONS,
  PATH_DASHBOARD,
  PATH_DC_CONFIGURATION,
  PATH_PRICING,
  PATH_PRODUCT,
  PATH_PROPERTIES,
  PATH_REPORTS,
  PATH_TENANT_ASSIGN,
  PATH_DELETE_CASES,
  PATH_MANAGE_CATEGORY,
  PATH_MANAGE_DOCUMENT_TYPE,
  PATH_DISPOSITION,
  PATH_DECISIONING_AUDIT,
  PATH_EMBEDDING_IFRAME,
  PATH_PRODUCT_PLAN,
  PATH_ACCOUNTING,
} from "./RouteConstants";
import AssignDC from "../AssignDC/AssignDC";
import DcConguration from "../DcConguration/Dc-configuration";
import Coupons from "../Coupons/Coupons";
import DeleteCases from "../deleteCases/DeleteCases";
import Pricing from "../pricing/Pricing";
import ManageCategory from "../Document/ManageCategory/ManageCategory";
import ManageDocumentType from "../Document/ManageDocumentType/ManageDocumentType";
import Products from "../MasterProperties/Products/Products";
import Disposition from "../Disposition/Disposition";
import DecisioningAudit from "../DecisioningAudit/DecisioningAudit";
import EmbeddingIframe from "../EmbeddingIframe";
import ProductPlan from "../MasterProperties/ProductPlan/ProductPlan";
import Accounting from "../Accounting/Accounting";
import { getUserDetails } from "../../shared/Utility/Utility";

type Props = {};

const AppRoutes: React.FunctionComponent<Props> = () => {

  const isAdmin = !((getUserDetails()?.allUserRoles?.includes("ROLE_ACCOUNTANT")) && (!getUserDetails()?.allUserRoles?.includes("ROLE_ADMIN")))

  return (
    <Routes>
      {isAdmin && <Route path={PATH_EMBEDDING_IFRAME} element={<EmbeddingIframe />} /> }
      {isAdmin && <Route path={PATH_DASHBOARD} element={<Dashboard />} />}
      {isAdmin && <Route path={PATH_PROPERTIES} element={<Properties />} />}
      {isAdmin && <Route path={PATH_TENANT_ASSIGN} element={<AssignDC />} />}
      {isAdmin && <Route path={PATH_APP_CONTENT} element={<AppContent />} />}
      {isAdmin && <Route path={PATH_REPORTS} element={<Reports />} />}
      {isAdmin && <Route path={PATH_DC_CONFIGURATION} element={<DcConguration />} />}
      {isAdmin && <Route path={PATH_PRODUCT} element={<Products />} />}
      {isAdmin && <Route path={PATH_COUPONS} element={<Coupons />} />}
      {isAdmin && <Route path={PATH_DELETE_CASES} element={<DeleteCases />} />}
      {isAdmin && <Route path={PATH_PRICING} element={<Pricing />} />}
      {isAdmin && <Route path={PATH_MANAGE_CATEGORY} element={<ManageCategory />} />}
      {isAdmin && <Route path={PATH_DISPOSITION} element={<Disposition />} />}
      {isAdmin && <Route path={PATH_DECISIONING_AUDIT} element={<DecisioningAudit />} />}
      {isAdmin && <Route path={PATH_PRODUCT_PLAN} element={<ProductPlan />} />}
      {isAdmin && <Route path={PATH_MANAGE_DOCUMENT_TYPE} element={<ManageDocumentType />} />}
      <Route path={PATH_ACCOUNTING} element={<Accounting />} />
      <Route path="/*" element={<Navigate to={isAdmin ?  PATH_DASHBOARD : PATH_ACCOUNTING} />} />
    </Routes>
  );
};

export default AppRoutes;
