import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const disbursalSlice = createSlice({
  name: "DisbursementReducer",
  initialState: { pageNo: 1, pageSize: 10, disbursalSelectedCase:0, disbursalSelectedAmount:0, disbursalInvoiceAmount :0},
  reducers: {
    setDisbursalPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload;
    },
    setDisbursalPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setDisbursalSelectedCase:(state, action: PayloadAction<number>) => {
      state.disbursalSelectedCase = action.payload;
    },
    setDisbursalSelectedAmount:(state, action: PayloadAction<number>) => {
      state.disbursalSelectedAmount = action.payload;
    },
    setDisbursalInvoiceAmount:(state, action: PayloadAction<number>) => {
      state.disbursalInvoiceAmount = action.payload;
    },
  }
});

export const { setDisbursalPageNo, setDisbursalPageSize, setDisbursalSelectedCase, setDisbursalSelectedAmount, setDisbursalInvoiceAmount } = disbursalSlice.actions;
export default disbursalSlice.reducer;